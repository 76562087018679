import React, { useEffect, useState } from 'react';
import { Grid, Box, Typography, Button, CircularProgress } from '@mui/material';
import { Link } from 'react-router-dom';
import { getFilteredNewsData, getAuthorsById } from '../../../Services/AdminServices';
import parse from 'html-react-parser';
import { useNavigate } from 'react-router-dom';
const formatNewsDetailUrl = (slug) => {
  return `/news-articles/getNewsBySlug/${encodeURIComponent(slug)}`;
};

const extractImageFromContent = (htmlContent) => {
  if (!htmlContent) return '/DefaultImages/news_default_img.jpeg';

  let imageUrl = '/DefaultImages/news_default_img.jpeg';
  parse(htmlContent, {
    replace: (domNode) => {
      if (domNode.name === 'img' && domNode.attribs?.src) {
        imageUrl = domNode.attribs.src;
      }
      return null;
    },
  });

  const serverBaseURL = 'http://localhost:5000';
  if (!imageUrl.startsWith('http')) {
    imageUrl = `${serverBaseURL}${imageUrl}`;
  }

  return imageUrl;
};

function Articleshome() {
  const [articlesData, setArticlesData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [authorDetails, setAuthorDetails] = useState({});

  useEffect(() => {
    const fetchArticles = async () => {
      try {
        setLoading(true);
        const data = { 
          categoryName: 'general', 
          type: 'articles', 
          count: 4 
        };
        const response = await getFilteredNewsData(data);

        if (response?.data?.success && Array.isArray(response.data.latestNews)) {
          setArticlesData(response.data.latestNews);
          
          // Fetch author details for each article
          const authorIds = response.data.latestNews
            .map(article => article.author)
            .filter(authorId => authorId); // Filter out any null/undefined authors

          const uniqueAuthorIds = [...new Set(authorIds)]; // Remove duplicates
          
          const authorsData = {};
          await Promise.all(
            uniqueAuthorIds.map(async (authorId) => {
              try {
                const authorResponse = await getAuthorsById(authorId);
                if (authorResponse?.data?.success) {  // Check for success in response
                  authorsData[authorId] = authorResponse.data.author; // Access the author object
                }
              } catch (error) {
                console.error(`Error fetching author ${authorId}:`, error);
              }
            })
          );
          
          setAuthorDetails(authorsData);
        } else {
          setError('No general articles found.');
        }
      } catch (err) {
        setError('Failed to fetch general articles: ' + err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchArticles();
  }, []);

  const getAuthorDisplay = (authorId) => {
    if (!authorId) return 'Unknown Author';
    const authorData = authorDetails[authorId];
    return authorData?.name || 'Unknown Author';
  };
  const navigate = useNavigate();
  const handleViewMore = () => {
    navigate('/articles');
  };

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Typography color="error" sx={{ textAlign: 'center', mt: 2 }}>
        {error}
      </Typography>
    );
  }

  return (
    <div>
      <Grid container spacing={2} sx={{ pl: 2, pr: 2, pb: 2 }}>
        {/* Grid for Latest Articles Button and View More Button */}
        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Button
            variant="contained"
            sx={{
              fontWeight: 'bold',
              backgroundColor: '#AD62AA',
              mt: 2, mb: 0, ml: { xs: 3, sm: 1 },
              '&:hover': { backgroundColor: '#04049a' },
            }}
          >
            Latest Articles
          </Button>

    
          
        </Grid>

        {articlesData.map((article, index) => {
          const imageUrl = extractImageFromContent(article.content);
          const articleDetailUrl = formatNewsDetailUrl(article.slug);
          const authorDisplay = getAuthorDisplay(article.author);

          return (
            <Grid item xs={12} sm={6} md={3} key={index}>
              <Box className="article-item" sx={{ borderRadius: 2, overflow: 'hidden', ml: { xs: 3, sm: 1 }, mr: { xs: 1, sm: 1 } }}>
                <Box component="img" src={imageUrl} alt={article.title} sx={{ width: '100%', height: 200, objectFit: 'cover' }} />
                <Link to={articleDetailUrl} style={{ textDecoration: 'none' }}>
                  <Typography variant="h7" sx={{ fontWeight: 'bold', mt: 1, color: '#C37028' }}>
                    {article.title}
                  </Typography>
                </Link>

                <Typography variant="body2" sx={{ color: 'text.secondary', mt: 1, mb: 2 }}>
                  <strong>By:</strong> {authorDisplay}
                </Typography>
              </Box>
            </Grid>
          );
        })}

<Box sx={{ display: 'flex', justifyContent: 'center', mt: {xs:1,sm:2} ,ml:{xs:20,sm:'46%'}}}>
          <Button
            variant="outlined"
            onClick={handleViewMore}
            sx={{
              fontWeight: 'bold',
              color: '#AD62AA',
              textTransform:'none',
              borderColor: '#AD62AA',
              '&:hover': {
                borderColor: '#04049a',
                color: '#04049a',
              },
            }}
          >
            View More
          </Button>
        </Box>



      </Grid>
    </div>
  );
}

export default Articleshome;
