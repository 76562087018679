import React, { useState, useEffect } from 'react';
import {
  Container,
  TextField,
  Button,
  Box,
  Typography,
  Card,
  CardContent,
  Grid,
  Avatar,
  CircularProgress,
  Snackbar,
  Alert
} from '@mui/material';
import { CloudUpload as CloudUploadIcon } from '@mui/icons-material';
import ResponsiveDrawer from '../Components/Drawer/Drawer';
import { getCurrentUser, updateProfile } from '../../Services/AdminServices';

function AdminDashboard() {
  const [profile, setProfile] = useState({
  
    email: '',
    contact: '',
    avatar: null,
    avatarUrl: '',
    upiId: '',
    whatsappNumber: '',
    qrCode: null,
    qrCodeUrl: ''
  });

  const [loading, setLoading] = useState(false);
  const [isLoadingProfile, setIsLoadingProfile] = useState(true);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success'
  });

  const fetchProfileData = async () => {
    try {
      setIsLoadingProfile(true);
      const response = await getCurrentUser();
      console.log('API Response:', response); // Debug log

      // Match the backend schema structure
      const userData = response.data?.data;
      setProfile(prevProfile => ({
        ...prevProfile,
       
        email: userData.email || '',
        contact: userData.contact || '',
        upiId: userData.upiId || '',
        whatsappNumber: userData.whatsappNumber || '',
        // Handle nested image URLs
        avatarUrl: userData.avatar?.url || '',
        qrCodeUrl: userData.qrCode?.url || ''
      }));
      
      console.log('Updated Profile State:', profile); // Debug log
    } catch (error) {
      console.error('Error fetching profile:', error);
      handleSnackbar('Failed to load profile data.', 'error');
    } finally {
      setIsLoadingProfile(false);
    }
  };

  useEffect(() => {
    fetchProfileData();
  }, []);

  const handleSnackbar = (message, severity = 'success') => {
    setSnackbar({ open: true, message, severity });
  };

  const handleCloseSnackbar = () => {
    setSnackbar(prev => ({ ...prev, open: false }));
  };

  const handleFileUpload = (e, fieldName) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (event) => {
        setProfile(prev => ({
          ...prev,
          [fieldName]: file,
          [`${fieldName}Url`]: event.target.result
        }));
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    const formData = new FormData();
    // Add text fields
   
    formData.append('email', profile.email);
    formData.append('contact', profile.contact);
    formData.append('upiId', profile.upiId);
    formData.append('whatsappNumber', profile.whatsappNumber);

    // Add files only if new ones were uploaded
    if (profile.avatar instanceof File) {
      formData.append('avatar', profile.avatar);
    }
    if (profile.qrCode instanceof File) {
      formData.append('qrCode', profile.qrCode);
    }

    try {
      setLoading(true);
      const response = await updateProfile(formData);
      
      if (response.data.success) {
        handleSnackbar('Profile updated successfully!');
        await fetchProfileData(); // Refresh the data
      } else {
        handleSnackbar('Failed to update profile.', 'error');
      }
    } catch (error) {
      console.error('Update error:', error);
      handleSnackbar(`Error: ${error.message || 'Something went wrong!'}`, 'error');
    } finally {
      setLoading(false);
    }
  };

  if (isLoadingProfile) {
    return (
      <Container maxWidth="sm" sx={{ mt: 8, display: 'flex', justifyContent: 'center' }}>
        <CircularProgress />
      </Container>
    );
  }

  return (
    <Container maxWidth="sm" sx={{ mt: 10 }}>
      <Grid item xs={12} lg={2}>
        <ResponsiveDrawer />
      </Grid>
      <Box >
        <CardContent>
          <Typography variant="h5" gutterBottom sx={{ fontWeight: 'bold',mb:3 }}>
            
            Update Profile Details
          </Typography>

          <form onSubmit={handleSubmit}>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
             
              <TextField
                label="Email"
                variant="outlined"
                type="email"
                value={profile.email}
                onChange={(e) => setProfile(prev => ({ ...prev, email: e.target.value }))}
                fullWidth
                required
              />
              <TextField
                label="Contact"
                variant="outlined"
                value={profile.contact}
                onChange={(e) => setProfile(prev => ({ ...prev, contact: e.target.value }))}
                fullWidth
                required
              />

              <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2 }}>
                <input
                  type="file"
                  accept="image/*"
                  onChange={(e) => handleFileUpload(e, 'avatar')}
                  style={{ display: 'none' }}
                  id="avatar-upload"
                />
                <label htmlFor="avatar-upload">
                  <Button
                    variant="contained"
                    component="span"
                    startIcon={<CloudUploadIcon />}
                    sx={{ bgcolor: '#A56266'}}
                  >
                    Upload Avatar
                  </Button>
                </label>
                {profile.avatarUrl && (
                  <Avatar
                    src={profile.avatarUrl}
                    alt="Avatar Preview"
                    sx={{ width: 100, height: 100 }}
                  />
                )}
              </Box>

              <TextField
                label="UPI ID"
                variant="outlined"
                value={profile.upiId}
                onChange={(e) => setProfile(prev => ({ ...prev, upiId: e.target.value }))}
                fullWidth
                required
              />
              <TextField
                label="WhatsApp Number"
                variant="outlined"
                value={profile.whatsappNumber}
                onChange={(e) => setProfile(prev => ({ ...prev, whatsappNumber: e.target.value }))}
                fullWidth
                required
              />

              <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 2 }}>
                <input
                  type="file"
                  accept="image/*"
                  onChange={(e) => handleFileUpload(e, 'qrCode')}
                  style={{ display: 'none' }}
                  id="qr-upload"
                />
                <label htmlFor="qr-upload">
                  <Button
                    variant="contained"
                    component="span"
                    startIcon={<CloudUploadIcon />}
                    sx={{ bgcolor: '#A56266'}}
                  >
                    Upload QR Code
                  </Button>
                </label>
                {profile.qrCodeUrl && (
                  <Box
                    component="img"
                    src={profile.qrCodeUrl}
                    alt="QR Code Preview"
                    sx={{ width: 150, height: 150, objectFit: 'contain' }}
                  />
                )}
              </Box>

              <Button
                variant="contained"
                type="submit"
                disabled={loading}
                sx={{
                  bgcolor: '#A56266',
                
                  width: '200px',
                  alignSelf: 'center',
                  mt: 2
                }}
              >
                {loading ? <CircularProgress size={24} color="inherit" /> : 'Save Changes'}
              </Button>
            </Box>
          </form>
        </CardContent>
      </Box>

      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbar.severity}
          variant="filled"
          sx={{ width: '100%' }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Container>
  );
}

export default AdminDashboard;