import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { 
  Box, 
  Button, 
  Card, 
  Table, 
  TableBody, 
  TableCell, 
  TableContainer, 
  TableHead, 
  TableRow, 
  TextField, 
  Typography, 
  IconButton, 
  Container, 
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions
} from '@mui/material';

import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import ResponsiveDrawer from '../../Admin/Components/Drawer/Drawer';
import { useNavigate } from 'react-router-dom';
import { getAllCategories, deleteCategoryById } from '../../Services/AdminServices';
import toast, { Toaster } from 'react-hot-toast';

const Item = styled(Card)(({ theme }) => ({
  backgroundColor: "#fff",
  boxShadow: 'none',
  padding: 20,
}));

const AdminCategories = ({ ...props }) => {
  const navigate = useNavigate();
  const [categories, setCategories] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [categoryToDelete, setCategoryToDelete] = useState(null);

  useEffect(() => {
    fetchCategories();
  }, []);

  const fetchCategories = async () => {
    try {
      const response = await getAllCategories();
      setCategories(response?.data?.categories || []);
    } catch (error) {
      toast.error('Error fetching categories');
    }
  };

  const handleClick = () => {
    navigate('/admin/newcategories');
  };

  const handleDeleteClick = (categoryId) => {
    setCategoryToDelete(categoryId);
    setDeleteDialogOpen(true);
  };

  const handleDeleteConfirm = async () => {
    try {
      const response = await deleteCategoryById(categoryToDelete);
      toast.success(response?.data?.message);
      fetchCategories();
    } catch (error) {
      toast.error('Error deleting category');
    } finally {
      setDeleteDialogOpen(false);
      setCategoryToDelete(null);
    }
  };

  const handleDeleteCancel = () => {
    setDeleteDialogOpen(false);
    setCategoryToDelete(null);
  };

  const handleEdit = (id) => {
    navigate(`/admin/edit-category/${id}`);
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const filteredCategories = categories.filter(
    (cat) => cat.name && cat.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <Container>
      <Grid container spacing={2}>
        {/* Left Drawer Section */}
        <Grid item xs={12} sm={3} md={2} lg={2}>
          <ResponsiveDrawer {...props} />
        </Grid>

        {/* Right Content Section */}
        <Grid item xs={12} sm={10} md={10} lg={10}>
          <Box sx={{  mt: { xs: 8, sm: 12 }, display: 'flex', alignItems: 'center', gap: { xs: 18, sm: 88 } }}>
            <Typography variant="h5" gutterBottom sx={{ fontWeight: 'bold', marginLeft: { xs: 3, sm: 2 } }}>
              Category
            </Typography>
            <Button
              variant="contained"
              style={{ backgroundColor: "#A56266", color: "white" }}
              onClick={handleClick}
            >
              <AddIcon /> Create
            </Button>
          </Box>

          <Box sx={{ marginRight: { xs: -2, sm: 0 } }}>
            <TableContainer component={Item}>
              <Table sx={{ minWidth: 320 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ backgroundColor: "#caada7", color: "white", fontWeight: "bold" }}>Action</TableCell>
                    <TableCell sx={{ backgroundColor: "#d18898", color: "white", fontWeight: "bold" }}>Category</TableCell>
                    <TableCell sx={{ backgroundColor: "#A56266", color: "white", fontWeight: "bold" }}>Type</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredCategories.length === 0 ? (
                    <TableRow>
                      <TableCell colSpan={3} align="center">No categories found</TableCell>
                    </TableRow>
                  ) : (
                    filteredCategories.map((cat, index) => (
                      <TableRow key={cat._id}>
                        <TableCell align="left" style={{ backgroundColor: index % 2 === 0 ? '#f3f4d3' : '#e0dab8' }}>
                        <IconButton 
                            title="Edit Category" 
                            onClick={() => handleEdit(cat._id)}
                          >
                            <EditIcon style={{ fontSize: '16px' }} />
                          </IconButton>
                          <IconButton 
                            title="Delete Category" 
                            onClick={() => handleDeleteClick(cat._id)}
                          >
                            <DeleteIcon style={{ fontSize: '16px' }} />
                          </IconButton>
                        </TableCell>
                        <TableCell align="left" style={{ backgroundColor: index % 2 === 0 ? '#f3f4d3' : '#e0dab8' }}>
                          <span style={{ fontSize: 17, fontWeight: 'bold' }}>{cat.name}</span>
                        </TableCell>
                        <TableCell align="left" style={{ backgroundColor: index % 2 === 0 ? '#f3f4d3' : '#e0dab8' }}>
                          <span style={{ fontWeight: 600 }}>{cat.type}</span>
                        </TableCell>
                      </TableRow>
                    ))
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Grid>
      </Grid>

      {/* Delete Confirmation Dialog */}
      <Dialog
        open={deleteDialogOpen}
        onClose={handleDeleteCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Confirm Delete"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this category? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteCancel} color="primary">
            Cancel
          </Button>
          <Button 
            onClick={handleDeleteConfirm} 
            style={{ backgroundColor: "#A56266", color: "white" }}
            variant="contained"
            autoFocus
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      <Toaster position="top-center" reverseOrder={false} />
    </Container>
  );
};

export default AdminCategories;