import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { Box, Button, Card, Table,  DialogContentText, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography, IconButton, Container, Grid, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { useNavigate } from 'react-router-dom';
import { getAllMagazines, deleteMagazineById, updateMagazine } from '../../Services/AdminServices'; 
import toast, { Toaster } from 'react-hot-toast';
import ResponsiveDrawer from '../../Admin/Components/Drawer/Drawer'; 

const Item = styled(Card)(({ theme }) => ({
  backgroundColor: "#fff",
  boxShadow: 'none',
  padding: 20,
}));

const AdminMagazine = ({ ...props }) => {
  const navigate = useNavigate();
  const [magazines, setMagazines] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [openDialog, setOpenDialog] = useState(false);
  const [deleteMagazineId, setDeleteMagazineId] = useState(null); // Store magazine ID to delete

  useEffect(() => {
    fetchMagazines();
  }, []);

  const fetchMagazines = async () => {
    try {
      const response = await getAllMagazines();
      setMagazines(response?.data?.magazines || []);
    } catch (error) {
      toast.error('Error fetching magazines');
    }
  };

  const handleClick = () => {
    navigate('/admin/newmagazine'); // Navigate to create new magazine page
  };

  const formatMonth = (dateString) => {
    const date = new Date(dateString);
    return isNaN(date.getTime()) ? 'N/A' : date.toLocaleString('default', { month: 'long' });
  };

  const handleDelete = async () => {
    try {
      const response = await deleteMagazineById(deleteMagazineId);
      toast.success(response?.data?.message);
      setOpenDialog(false); // Close the dialog
      fetchMagazines(); // Refresh the magazine list after deletion
    } catch (error) {
      toast.error('Error deleting magazine');
    }
  };

  const handleEdit = (magazineId) => {
    navigate(`/admin/edit-magazine/${magazineId}`); // Navigate to edit magazine page
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  // Filtering magazines by name
  const filteredMagazines = magazines.filter(
    (mag) => mag.name && mag.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const openDeleteDialog = (magazineId) => {
    setDeleteMagazineId(magazineId);
    setOpenDialog(true);
  };

  const closeDeleteDialog = () => {
    setOpenDialog(false);
    setDeleteMagazineId(null);
  };

  return (
    <Container>
      <Grid container spacing={2}>
        {/* Left Drawer Section */}
        <Grid item xs={12} sm={3} md={2} lg={2}>
          <ResponsiveDrawer {...props} />
        </Grid>

        {/* Right Content Section */}
        <Grid item xs={12} sm={10} md={10} lg={10}>
          <Box sx={{ mt: { xs: 10, sm: 12 }, display: 'flex', alignItems: 'center', gap: { xs: 18, sm: 88 } }}>
            <Typography variant="h5" gutterBottom sx={{ fontWeight: 'bold', marginLeft: { xs: 3, sm: 2 } }}>
              Magazine
            </Typography>
            <Button
              variant="contained"
              style={{ backgroundColor: "#A56266", color: "white" }}
              onClick={handleClick}
            >
              <AddIcon /> Create
            </Button>
          </Box>

          <Box sx={{ marginRight: { xs: -2, sm: 0 } }}>
            <TableContainer component={Item}>
              <Table sx={{ minWidth: 320 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ backgroundColor: "#caada7", color: "white", fontWeight: "bold" }}>Action</TableCell>
                    <TableCell sx={{ backgroundColor: "#d18898", color: "white", fontWeight: "bold" }}>Magazine</TableCell>
                    <TableCell sx={{ backgroundColor: "#A56266", color: "white", fontWeight: "bold" }}>Edition</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredMagazines.length === 0 ? (
                    <TableRow>
                      <TableCell colSpan={3} align="center">No magazines found</TableCell>
                    </TableRow>
                  ) : (
                    filteredMagazines.map((mag, index) => (
                      <TableRow key={mag._id}>
                        <TableCell align="left" style={{ backgroundColor: index % 2 === 0 ? '#f3f4d3' : '#e0dab8' }}>
                          <IconButton title="Edit Magazine" onClick={() => handleEdit(mag._id)}>
                            <EditIcon style={{ fontSize: '16px' }} />
                          </IconButton>
                          <IconButton title="Delete Magazine" onClick={() => openDeleteDialog(mag._id)}>
                            <DeleteIcon style={{ fontSize: '16px' }} />
                          </IconButton>
                        </TableCell>
                        <TableCell align="left" style={{ backgroundColor: index % 2 === 0 ? '#f3f4d3' : '#e0dab8' }}>
                          <span style={{ fontSize: 17, fontWeight: 'bold' }}>{mag.name}</span>
                        </TableCell>
                        <TableCell align="left" style={{ backgroundColor: index % 2 === 0 ? '#f3f4d3' : '#e0dab8' }}>
                          <span style={{ fontWeight: 600 }}>
                            {formatMonth(mag.publicationDate)}
                          </span>
                        </TableCell>
                      </TableRow>
                    ))
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Grid>
      </Grid>

      {/* Confirmation Dialog */}
     

        <Dialog
        open={openDialog}
        onClose={closeDeleteDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Confirm Delete"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this Magazine? This action cannot be undone.
          </DialogContentText>
        </DialogContent>


<DialogActions>
          <Button onClick={closeDeleteDialog} color="primary">
            Cancel
          </Button>
          <Button 
            onClick={handleDelete} 
            style={{ backgroundColor: "#A56266", color: "white" }}
            variant="contained"
            autoFocus
          >
            Delete
          </Button>
        </DialogActions>

        
      </Dialog>

      <Toaster position="top-center" reverseOrder={false} />
    </Container>
  );
};

export default AdminMagazine;
