import React, { useEffect, useState } from 'react';
import {
  Box, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography,
  IconButton, Container, Grid, Dialog, DialogActions, DialogContent, DialogTitle
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { useNavigate } from 'react-router-dom';
import { getFilteredNewsData, deleteNewsarticlesBySlug } from '../../Services/AdminServices';
import toast, { Toaster } from 'react-hot-toast';
import ResponsiveDrawer from '../../Admin/Components/Drawer/Drawer';
import parse from 'html-react-parser';
import { saveToLocalStorage } from '../../Helper/helper';

const PublishedNews = ({ ...props }) => {
  const navigate = useNavigate();
  const [newsArticles, setNewsArticles] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [loading, setLoading] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedNewsSlug, setSelectedNewsSlug] = useState(null);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isEditMode, setisEditMode] = useState(false);

  useEffect(() => {
    fetchNewsArticles();
  }, []);

  const fetchNewsArticles = async () => {
    setLoading(true);
    try {
      const data = {
        categoryName: '',
        type: '',
        count: 100,
      };
      const response = await getFilteredNewsData(data);
      if (response?.data?.success && Array.isArray(response.data.latestNews)) {
        setNewsArticles(response.data.latestNews);
      } else {
        toast.error('No news articles found.');
      }
    } catch (error) {
      console.error('Error fetching news articles:', error);
      toast.error('Error fetching news articles');
    } finally {
      setLoading(false);
    }
  };

  const handleClick = () => {
    navigate('/admin/create-news');
  };

  const handleDeleteClick = (newsSlug) => {
    setSelectedNewsSlug(newsSlug);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    if (!isDeleting) {
      setOpenDialog(false);
      setSelectedNewsSlug(null);
    }
  };

  const handleDelete = async () => {
    if (!selectedNewsSlug || isDeleting) return;
  
    setIsDeleting(true);
    const loadingToast = toast.loading('Deleting article...');
  
    try {
      
      const response = await deleteNewsarticlesBySlug(selectedNewsSlug);
  
    
      if (response?.data?.success) {
        
        setNewsArticles((prevArticles) => 
          prevArticles.filter((article) => article.slug !== selectedNewsSlug)
        );
        toast.success(response?.data?.message || 'Article deleted successfully');
        setOpenDialog(false); 
      } else {
       
        toast.error(response?.data?.message || 'Failed to delete article');
      }
    } catch (error) {
      console.error('Delete error:', error);
      toast.error(error?.response?.data?.message || 'Error deleting article');
    } finally {
      setIsDeleting(false); 
      toast.dismiss(loadingToast); 
    }
  };
  

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const filteredNewsArticles = newsArticles.filter(
    (news) => news.title && news.title.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const renderFilteredContent = (htmlContent, news) => {
    let arr = [];

    parse(htmlContent, {
      replace: (domNode) => {
        arr.push(domNode);
        return null;
      },
    });

    const imageNode = arr.find((node) => node?.name === 'img');
    const image = imageNode ? (
      <Box
        component="img"
        alt="Image"
        src={imageNode?.attribs?.src || '/DefaultImages/news_default_img.jpeg'}
        sx={{
          width: '120px',
          height: '80px',
          objectFit: 'cover',
          borderRadius: '4px',
          cursor: 'pointer'
        }}
        onClick={() => {
          saveToLocalStorage(window.location.pathname, window.scrollY);
          navigate(`/news-articles/getNewsBySlug/${news?.slug}`);
        }}
      />
    ) : (
      <Box
        component="img"
        alt="Default"
        src="/DefaultImages/news_default_img.jpeg"
        sx={{
          width: '120px',
          height: '80px',
          objectFit: 'cover',
          borderRadius: '4px',
          cursor: 'pointer'
        }}
        onClick={() => {
          saveToLocalStorage(window.location.pathname, window.scrollY);
          navigate(`/news-articles/getNewsBySlug/${news?.slug}`);
        }}
      />
    );

    return { image };
  };

  return (
    <Container>

      <Grid container spacing={2}>
        {/* Left Drawer Section */}
        <Grid item xs={12} sm={3} md={2} lg={2}>
          <ResponsiveDrawer {...props} />
        </Grid>

        {/* Right Content Section */}
        <Grid item xs={12} sm={9} md={10} lg={10}>
          <Box sx={{ mt: {xs: 10, sm: 12}, display: 'flex', alignItems: 'center', justifyContent: 'space-between', px: 2, mb: 2 }}>
            <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
              News Articles
            </Typography>
            <Button
              variant="contained"
              sx={{ backgroundColor: "#A56266", '&:hover': { backgroundColor: "#8b4f53" } }}
              onClick={handleClick}
              startIcon={<AddIcon />}
            >
              Create
            </Button>
          </Box>

          <Box sx={{ px: 2 }}>
            <TextField
              fullWidth
              variant="outlined"
              placeholder="Search by title..."
              value={searchQuery}
              onChange={handleSearchChange}
              sx={{ mb: 2 }}
            />
            
            <TableContainer component={Box}>
              <Table sx={{ minWidth: 320 }} aria-label="news articles table">
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ backgroundColor: '#caada7', color: 'white', fontWeight: 'bold', width: '120px' }}>
                      Action
                    </TableCell>
                    <TableCell sx={{ backgroundColor: '#d18898', color: 'white', fontWeight: 'bold', width: '40%' }}>
                      Title
                    </TableCell>
                    <TableCell sx={{ backgroundColor: '#A56266', color: 'white', fontWeight: 'bold', width: '150px' }}>
                      Published Date
                    </TableCell>
                    <TableCell sx={{ backgroundColor: '#A56266', color: 'white', fontWeight: 'bold', width: '150px' }}>
                      Image
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {loading ? (
                    <TableRow>
                      <TableCell colSpan={4} align="center">Loading...</TableCell>
                    </TableRow>
                  ) : filteredNewsArticles.length === 0 ? (
                    <TableRow>
                      <TableCell colSpan={4} align="center">No news articles found</TableCell>
                    </TableRow>
                  ) : (
                    filteredNewsArticles.map((news, index) => {
                      const { image } = renderFilteredContent(news.content, news);
                      return (
                        <TableRow key={news._id}>
                          <TableCell align="left" sx={{ backgroundColor: index % 2 === 0 ? '#f3f4d3' : '#e0dab8' }}>
                            
                           <IconButton
  title="Edit News"
  onClick={() => navigate(`/admin/edit-news/${news.slug}`)}
  disabled={isEditMode}
>
  <EditIcon sx={{ fontSize: '20px' }} />
</IconButton>

<IconButton
                              title="Delete News"
                              onClick={() => handleDeleteClick(news.slug)}
                              disabled={isDeleting}
                            >
                              <DeleteIcon sx={{ fontSize: '20px' }} />
                            </IconButton>


                          </TableCell>
                          <TableCell align="left" sx={{ backgroundColor: index % 2 === 0 ? '#f3f4d3' : '#e0dab8' }}>
                            <Typography sx={{ fontSize: 17, fontWeight: 'bold' }}>
                              {parse(news.title)}
                            </Typography>
                          </TableCell>
                          <TableCell align="left" sx={{ backgroundColor: index % 2 === 0 ? '#f3f4d3' : '#e0dab8' }}>
                            <Typography sx={{ fontWeight: 600 }}>
                              {new Date(news.createdAt).toLocaleDateString('en-GB')}
                            </Typography>
                          </TableCell>
                          <TableCell align="left" sx={{ backgroundColor: index % 2 === 0 ? '#f3f4d3' : '#e0dab8', p: 1 }}>
                            {image}
                          </TableCell>
                        </TableRow>
                      );
                    })
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>

          {/* Delete Confirmation Dialog */}
          <Dialog 
            open={openDialog} 
            onClose={handleCloseDialog}
            aria-labelledby="delete-dialog-title"
          >
            <DialogTitle id="delete-dialog-title">Confirm Deletion</DialogTitle>
            <DialogContent>
              <Typography variant="body1">
                Are you sure you want to delete this NewsArticle? This action cannot be undone.
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button 
                onClick={handleCloseDialog} 
                disabled={isDeleting}
                sx={{ color: 'gray' }}
              >
                Cancel
              </Button>
              <Button 
                onClick={handleDelete} 
                disabled={isDeleting}
                variant="contained"
                sx={{ 
                  backgroundColor: "#A56266", 
                  '&:hover': { backgroundColor: "#8b4f53" }
                }}
              >
                {isDeleting ? 'Deleting...' : 'Delete'}
              </Button>
            </DialogActions>
          </Dialog>
        </Grid>
      </Grid>
      <Toaster position="top-center" reverseOrder={false} />
    </Container>
  );
};

export default PublishedNews;