import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  DialogContentText,
  Typography,
  IconButton,
  Card,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Container,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import ResponsiveDrawer from '../../Admin/Components/Drawer/Drawer';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import BookIcon from '@mui/icons-material/Book';
import SchoolIcon from '@mui/icons-material/School';
import WebIcon from '@mui/icons-material/Web';
import AddCircleIcon from '@mui/icons-material/AddCircle';

import DeleteIcon from '@mui/icons-material/Delete';
import SearchIcon from '@mui/icons-material/Search';
import { useNavigate } from 'react-router-dom';
import toast, { Toaster } from 'react-hot-toast';
import { getFilteredNewsData, deleteNewsarticlesBySlug } from '../../Services/AdminServices';
import parse from 'html-react-parser';
import { saveToLocalStorage } from '../../Helper/helper';

function Dashboard({ drawerWidth = 240, ...props }) {
  const [loading, setLoading] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedNewsSlug, setSelectedNewsSlug] = useState(null);
  const [newsArticles, setNewsArticles] = useState([]);
  const [deletingIds, setDeletingIds] = useState(new Set());
  const [searchQuery, setSearchQuery] = useState('');
  const [isDeleting, setIsDeleting] = useState(false);
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));
 const [isEditMode, setisEditMode] = useState(false);

  useEffect(() => {
    fetchNewsArticles();
  }, []);

  const fetchNewsArticles = async () => {
    setLoading(true);
    try {
      const data = {
        categoryName: '',
        type: '',
        count: 100,
      };
      const response = await getFilteredNewsData(data);
      if (response?.data?.success && Array.isArray(response.data.latestNews)) {
        setNewsArticles(response.data.latestNews);
      } else {
        toast.error('No news articles found.');
      }
    } catch (error) {
      console.error('Error fetching news articles:', error);
      toast.error('Error fetching news articles');
    } finally {
      setLoading(false);
    }
  };

  


  const handleDelete = async () => {
    if (!selectedNewsSlug) return;
    const loadingToast = toast.loading('Deleting article...');
    
    try {
      const response = await deleteNewsarticlesBySlug(selectedNewsSlug);
      if (response?.data?.success) {
        setNewsArticles(prevArticles => 
          prevArticles.filter(article => article.slug !== selectedNewsSlug)
        );
        toast.success('Article deleted successfully', { id: loadingToast });
      } else {
        toast.error('Failed to delete article', { id: loadingToast });
      }
    } catch (error) {
      toast.error('Error deleting article. Please try again.', { id: loadingToast });
    } finally {
      setOpenDialog(false);
      setDeletingIds(prev => {
        const next = new Set(prev);
        next.delete(selectedNewsSlug);
        return next;
      });
    }
  };

  const handleDeleteClick = (newsSlug) => {
    setSelectedNewsSlug(newsSlug);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleNavigation = (path) => {
    navigate(path);
  };

  const renderFilteredContent = (htmlContent, news) => {
    let arr = [];
    parse(htmlContent, {
      replace: (domNode) => {
        arr.push(domNode);
        return null;
      },
    });

    const imageNode = arr.find((node) => node?.name === 'img');
    const image = (
      <Box
        component="img"
        alt={imageNode ? "News Image" : "Default Image"}
        src={imageNode?.attribs?.src || '/DefaultImages/news_default_img.jpeg'}
        sx={{
          width: { xs: '80px', sm: '120px' },
          height: { xs: '60px', sm: '80px' },
          objectFit: 'cover',
          borderRadius: '4px',
          cursor: 'pointer',
          transition: 'transform 0.2s',
          '&:hover': {
            transform: 'scale(1.05)',
          }
        }}
        onClick={() => {
          saveToLocalStorage(window.location.pathname, window.scrollY);
          navigate(`/user/news/details/${news?.slug}`);
        }}
      />
    );

    return { image };
  };

  const filteredNewsArticles = newsArticles.filter(
    (news) => news.title && news.title.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const actionCards = [
    {
      title: "News",
      icon: NewspaperIcon,
      color: '#caada7',
      action: () => handleNavigation('/admin/publish'),
      buttonText: "Create News"
    },
    {
      title: "Publications",
      icon: BookIcon,
      color: '#d18898',
      action: () => handleNavigation('/admin/publications'),
      buttonText: "Publish Book"
    },
    {
      title: "Courses",
      icon: SchoolIcon,
      color: '#A56266',
      action: () => handleNavigation('/admin/courses'),
      buttonText: "Create Course"
    }
  ];

  return (
    <Box sx={{ display: 'flex', minHeight: '100vh' }}>
      <ResponsiveDrawer {...props} />
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          overflow: 'hidden',
        }}
      >
        <Container 
          maxWidth="xl" 
          sx={{ 
            mt:{xs:0,sm:8},
            py: { xs: 10, sm: 3, md: 4 },
            px: { xs: 4, sm: 2, md: 3 },
          }}
        >
          

         

          {/* Action Cards Grid */}
          <Grid container spacing={2} sx={{ mb: { xs: 3, sm: 4, md: 5 } }}>
            {actionCards.map((item, index) => (
              <Grid item xs={12} sm={6} md={4} key={index}>
                <Card 
                  elevation={3}
                  sx={{
                    bgcolor: item.color,
                    borderRadius: 3,
                    p: { xs: 1, sm: 3 },
                    mr:{xs:-2,sm:0},
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    transition: 'transform 0.2s, box-shadow 0.2s',
                    '&:hover': {
                      transform: 'translateY(-4px)',
                      boxShadow: 6,
                    }
                  }}
                >
                  <item.icon sx={{ 
                    fontSize: { xs: 36, sm: 48 }, 
                    color: 'white', 
                    mb: 1 
                  }} />
                  <Typography 
                    variant={isMobile ? "subtitle1" : "h6"} 
                    sx={{ 
                      color: 'white', 
                      mb: 1,
                      fontWeight: 'medium'
                    }}
                  >
                    {item.title}
                  </Typography>
                  <Button
                    variant="contained"
                    startIcon={<AddCircleIcon />}
                    size={isMobile ? "small" : "medium"}
                    sx={{
                      textTransform: 'none',
                      backgroundColor: 'white',
                      color: 'black',
                      borderRadius: 2,
                      mb: 1,
                      px: { xs: 2, sm: 3 },
                      py: { xs: 0.5, sm: 1 },
                      '&:hover': {
                        backgroundColor: '#f5f5f5',
                      }
                    }}
                    onClick={item.action}
                  >
                    {item.buttonText}
                  </Button>
                </Card>
              </Grid>
            ))}
          </Grid>

          {/* News Table */}
        
          <TableContainer component={Box}>
              <Table sx={{ minWidth: 320 }} aria-label="news articles table">
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ backgroundColor: '#caada7', color: 'white', fontWeight: 'bold', width: '120px' }}>
                      Action
                    </TableCell>
                    <TableCell sx={{ backgroundColor: '#d18898', color: 'white', fontWeight: 'bold', width: '40%' }}>
                      Title
                    </TableCell>
                    <TableCell sx={{ backgroundColor: '#A56266', color: 'white', fontWeight: 'bold', width: '150px' }}>
                      Published Date
                    </TableCell>
                    <TableCell sx={{ backgroundColor: '#caada7', color: 'white', fontWeight: 'bold', width: '150px' }}>
                      Image
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {loading ? (
                    <TableRow>
                      <TableCell colSpan={4} align="center">Loading...</TableCell>
                    </TableRow>
                  ) : filteredNewsArticles.length === 0 ? (
                    <TableRow>
                      <TableCell colSpan={4} align="center">No news articles found</TableCell>
                    </TableRow>
                  ) : (
                    filteredNewsArticles.map((news, index) => {
                      const { image } = renderFilteredContent(news.content, news);
                      return (
                        <TableRow key={news._id}>
                          <TableCell align="left" sx={{ backgroundColor: index % 2 === 0 ? '#f3f4d3' : '#e0dab8' }}>

                          <IconButton
  title="Edit News"
  onClick={() => navigate(`/admin/edit-news/${news.slug}`)}
  disabled={isEditMode}
>
  <EditIcon sx={{ fontSize: '20px' }} />
</IconButton>


                            <IconButton
                              title="Delete News"
                              onClick={() => handleDeleteClick(news.slug)}
                              disabled={isDeleting}
                            >
                              <DeleteIcon sx={{ fontSize: '20px' }} />
                            </IconButton>
                          </TableCell>
                          <TableCell align="left" sx={{ backgroundColor: index % 2 === 0 ? '#f3f4d3' : '#e0dab8' }}>
                            <Typography sx={{ fontSize: 17, fontWeight: 'bold' }}>
                              {parse(news.title)}
                            </Typography>
                          </TableCell>
                          <TableCell align="left" sx={{ backgroundColor: index % 2 === 0 ? '#f3f4d3' : '#e0dab8' }}>
                            <Typography sx={{ fontWeight: 600 }}>
                              {new Date(news.createdAt).toLocaleDateString('en-GB')}
                            </Typography>
                          </TableCell>
                          <TableCell align="left" sx={{ backgroundColor: index % 2 === 0 ? '#f3f4d3' : '#e0dab8', p: 1 }}>
                            {image}
                          </TableCell>
                        </TableRow>
                      );
                    })
                  )}
                </TableBody>
              </Table>
            </TableContainer>
       

          


          <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Confirm Delete"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this NewsArticle? This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Cancel
          </Button>
          <Button 
            onClick={handleDelete}
            style={{ backgroundColor: "#A56266", color: "white" }}
            variant="contained"
            autoFocus
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>







          {/* Toast Container */}
          <Toaster
            position="top-center"
            reverseOrder={false}
            toastOptions={{
              duration: 3000,
              style: {
                borderRadius: '8px',
                background: '#333',
                color: '#fff',
              },
            }}
          />
        </Container>
      </Box>
    </Box>
  );
}

export default Dashboard;