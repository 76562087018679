import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Container, TextField, Button, Typography, Box, Grid } from '@mui/material';
import ResponsiveDrawer from '../Components/Drawer/Drawer'; 
import { postCourse, updateCourse, getCourseById } from '../../Services/AdminServices';  // Ensure you have getCourseById to fetch course data
import { Toaster, toast } from 'react-hot-toast';

function NewCourse(props) {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const { id } = useParams(); // Get the course ID from the URL
  const isEditMode = Boolean(id); // Check if it's edit mode based on id presence

  const [formData, setFormData] = useState({
    courseName: '',
    teacherName: '',
    dateTime: '',
    googleClassroomLink: '',
    description: '',
    coverPhoto: null, 
    coverPreview: null, // Cover photo preview (image URL)
  });

  const [errors, setErrors] = useState({
    courseName: false,
    teacherName: false,
    dateTime: false,
    googleClassroomLink: false,
    description: false,
    coverPhoto: false,
  });

  // Fetch existing course data in edit mode
  useEffect(() => {
    if (isEditMode) {
      const fetchCourseData = async () => {
        try {
          const response = await getCourseById(id); // Fetch course data by ID
          if (response?.data?.success) {
            const course = response.data.course;
            setFormData({
              courseName: course.name || '',
              teacherName: course.teacherName || '',
              dateTime: course.dateTime || '',
              googleClassroomLink: course.googleClassroomLink || '',
              description: course.description || '',
              coverPhoto: null, // Cannot pre-fill file inputs, but we use coverPreview for display
            
              coverPreview: course.coverPhoto?.url || '', // URL for cover photo
            });
          } else {
            toast.error('Course not found');
          }
        } catch (error) {
          toast.error('Failed to fetch course data');
          console.error(error);
        }
      };
      fetchCourseData();
    }
  }, [isEditMode, id]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
    setErrors(prev => ({
      ...prev,
      [name]: false
    }));
  };

  const handleCoverPhotoChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setFormData(prev => ({
        ...prev,
        coverPhoto: file,
        coverPreview: URL.createObjectURL(file)
      }));
      setErrors(prev => ({
        ...prev,
        coverPhoto: false
      }));
    }
  };
 

  const validateForm = () => {
    const newErrors = {
      courseName: !formData.courseName,
      teacherName: !formData.teacherName,
      dateTime: !formData.dateTime,
      googleClassroomLink: !formData.googleClassroomLink,
      description: !formData.description,
      coverPhoto: isEditMode ? false : !formData.coverPhoto, // Cover photo is only required in create mode
    };

    setErrors(newErrors);
    return !Object.values(newErrors).some(error => error);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      toast.error("Please fill in all required fields");
      return;
    }

    setIsLoading(true);
    const submitFormData = new FormData();
    submitFormData.append('name', formData.courseName);
    submitFormData.append('teacherName', formData.teacherName);
    submitFormData.append('dateTime', formData.dateTime);
    submitFormData.append('googleClassroomLink', formData.googleClassroomLink);
    submitFormData.append('description', formData.description);

    // Handle cover photo upload if necessary
    if (formData.coverPhoto) {
      submitFormData.append('coverPhoto', formData.coverPhoto);
    }

    try {
      const loadingToast = toast.loading(isEditMode ? 'Updating course...' : 'Creating course...');
      let response;

      if (isEditMode) {
        response = await updateCourse(id, submitFormData);
      } else {
        response = await postCourse(submitFormData);
      }

      if (response?.data?.success) {
        toast.dismiss(loadingToast);
        toast.success(isEditMode ? 'Course updated successfully!' : 'Course created successfully!');
        navigate('/admin/courses');
      }
    } catch (error) {
      toast.error(error.response?.data?.message || 'Failed to process the request');
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Container maxWidth="lg" sx={{ padding: 0 }}>
      <Grid container spacing={2}>
        <Grid item xs={12} lg={2}>
          <ResponsiveDrawer {...props} />
        </Grid>
        <Grid item xs={12} lg={10} sx={{ mt: { xs: 4, sm: 10 } }}>
          <Box sx={{ padding: 4, height: '100%' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Typography variant="h5" gutterBottom sx={{ fontWeight: 'bold' }}>
                {isEditMode ? 'Edit Course' : 'Add Course'}
              </Typography>
            </div>
            <form onSubmit={handleSubmit}>
              <Box display="flex" flexDirection="column" alignItems="flex-start" mt={2}>
               <Button
                 variant="contained"
                 component="label"
                 sx={{ backgroundColor: '#A56266', color: 'white', mb: 2 }}
               >
                 Upload Cover Photo
                 <input
                   type="file"
                   hidden
                   accept="image/*"
                   onChange={handleCoverPhotoChange}
                 />
               </Button>
               {formData.coverPreview && (
                 <Box mt={2}>
                   <img
                     src={formData.coverPreview}
                     alt="Cover Preview"
                     style={{ maxWidth: '100%', maxHeight: '200px', borderRadius: '8px' }}
                   />
                 </Box>
               )}
               {errors.coverPhoto && <Typography color="error">Cover photo is required</Typography>}
             </Box>

              <TextField
                label="Course Name"
                fullWidth
                margin="normal"
                value={formData.courseName}
                onChange={handleInputChange}
                name="courseName"
                required
                sx={{ backgroundColor: '#f3f4d3', borderColor: '#829B3E' }}
              />
              <TextField
                label="Teacher Name"
                fullWidth
                margin="normal"
                value={formData.teacherName}
                onChange={handleInputChange}
                name="teacherName"
                required
                sx={{ backgroundColor: '#e0dab8', borderColor: '#829B3E' }}
              />
              <TextField
               
                fullWidth
                margin="normal"
                type="datetime-local"
                value={formData.dateTime}
                onChange={handleInputChange}
                name="dateTime"
                required
                sx={{ backgroundColor: '#f3f4d3', borderColor: '#829B3E' }}
              />
              <TextField
                label="Google Classroom Link"
                fullWidth
                margin="normal"
                value={formData.googleClassroomLink}
                onChange={handleInputChange}
                name="googleClassroomLink"
                required
                sx={{ backgroundColor: '#e0dab8', borderColor: '#829B3E' }}
              />
              <TextField
                label="Description"
                fullWidth
                margin="normal"
                multiline
                rows={4}
                value={formData.description}
                onChange={handleInputChange}
                name="description"
                required
                sx={{ backgroundColor: '#f3f4d3', borderColor: '#829B3E' }}
              />
              <Box display="flex" justifyContent="flex-end" mt={2}>
                <Button
                  type="submit"
                  variant="contained"
                  sx={{ backgroundColor: '#A56266', color: 'white' }}
                >
                  {isEditMode ? 'Update Course' : 'Add Course'}
                </Button>
              </Box>
            </form>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
}

export default NewCourse;
