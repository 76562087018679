import React, { useState, useEffect } from 'react';
import { Container, TextField, Button, Typography, Box, Grid } from '@mui/material';
import ResponsiveDrawer from '../Components/Drawer/Drawer';
import { Toaster, toast } from 'react-hot-toast';
import { postMagazine, getMagazineById, updateMagazine } from '../../Services/AdminServices';
import { useNavigate, useParams } from 'react-router-dom';

function NewMagazine(props) {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const { id } = useParams(); // Get the publication ID from the URL
  const isEditMode = Boolean(id); // Check if it's edit mode based on id presence

  const [formData, setFormData] = useState({
    name: '',
    publicationDate: '',
    coverPhoto: null, // Image file for the cover photo
    pdfFile: null, // File for the PDF version of the magazine
    coverPreview: null // Preview for the cover photo
  });

  const [errors, setErrors] = useState({
    name: false,
    publicationDate: false,
    coverPhoto: false,
    pdfFile: false
  });

  // Fetch existing data in edit mode
  useEffect(() => {
    if (isEditMode) {
      const fetchMagazineDetails = async () => {
        try {
          const response = await getMagazineById(id); // Fetch the magazine details by ID
          const magazine = response.data?.magazine; // Extract magazine details
          if (magazine) {
            setFormData({
              name: magazine.name || '',
              publicationDate: magazine.publicationDate ? magazine.publicationDate.split('T')[0] : '', // Ensure proper date format
              coverPhoto: null, // Cannot pre-fill file inputs, but we use coverPreview for display
            
              coverPreview: magazine.coverPhoto?.url || '', // URL for cover photo
              pdfFile: null, // Cannot pre-fill file inputs
            });
  
            // Set PDF file display information
            if (magazine.pdfFile?.url) {
              setFormData((prev) => ({
                ...prev,
                pdfFile: { name: magazine.pdfFile.url.split('/').pop(), url: magazine.pdfFile.url },
              }));
            }
          }
        } catch (error) {
          console.error('Failed to fetch magazine details:', error);
          toast.error('Unable to load magazine details');
        }
      };
      fetchMagazineDetails();
    }
  }, [isEditMode, id]);
  

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
    setErrors(prev => ({
      ...prev,
      [name]: false
    }));
  };

  const handleCoverPhotoChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setFormData(prev => ({
        ...prev,
        coverPhoto: file,
        coverPreview: URL.createObjectURL(file)
      }));
      setErrors(prev => ({
        ...prev,
        coverPhoto: false
      }));
    }
  };

  const handlePdfFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const fileSizeInMB = file.size / (1024 * 1024); // Convert bytes to MB
      if (fileSizeInMB > 2) {
        toast.error('The PDF file size exceeds 2MB. Please select a smaller file.');
      } else {
        setFormData(prev => ({
          ...prev,
          pdfFile: file
        }));
        setErrors(prev => ({
          ...prev,
          pdfFile: false
        }));
      }
    }
  };

  const validateForm = () => {
    const newErrors = {
      name: !formData.name,
      publicationDate: !formData.publicationDate,
      coverPhoto: isEditMode ? false : !formData.coverPhoto, // Cover photo is only required in create mode
      pdfFile: isEditMode ? false : !formData.pdfFile // PDF file is only required in create mode
    };

    setErrors(newErrors);
    return !Object.values(newErrors).some(error => error);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      toast.error("Please fill in all required fields");
      return;
    }

    setIsLoading(true);
    const submitFormData = new FormData();
    submitFormData.append('name', formData.name);
    submitFormData.append('publicationDate', formData.publicationDate);

    if (formData.coverPhoto) {
      submitFormData.append('coverPhoto', formData.coverPhoto);
    }
    if (formData.pdfFile) {
      submitFormData.append('pdfFile', formData.pdfFile);
    }

    try {
      const loadingToast = toast.loading(isEditMode ? 'Updating magazine...' : 'Creating magazine...');
      let response;

      if (isEditMode) {
        response = await updateMagazine(id, submitFormData); // Update request
      } else {
        response = await postMagazine(submitFormData); // Create request
      }

      if (response?.data?.success) {
        toast.dismiss(loadingToast);
        toast.success(isEditMode ? 'Magazine updated successfully!' : 'Magazine created successfully!');
        navigate('/admin/magazine'); // Redirect to magazine list
      }
    } catch (error) {
      toast.error(error.response?.data?.message || 'Failed to process the request');
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Container maxWidth="lg" sx={{ padding: 0 }}>
      <Grid container spacing={2}>
        <Grid item xs={12} lg={2}>
          <ResponsiveDrawer {...props} />
        </Grid>
        <Grid item xs={12} lg={10} sx={{ mt:{xs:5,sm:8} }}>
          <Box sx={{ padding: 4, height: '100%' }}>
            <Typography variant="h5" gutterBottom sx={{ fontWeight: 'bold' }}>
              {isEditMode ? 'Edit Magazine' : 'Add New Magazine'}
            </Typography>
            <form onSubmit={handleSubmit} encType="multipart/form-data">
              {/* Cover Photo Upload */}
              <Box display="flex" flexDirection="column" alignItems="flex-start" mt={2}>
  <Button
    variant="contained"
    component="label"
    sx={{ backgroundColor: '#A56266', color: 'white', mb: 2 }}
  >
    Upload Cover Photo
    <input
      type="file"
      hidden
      accept="image/*"
      onChange={handleCoverPhotoChange}
    />
  </Button>
  {formData.coverPreview && (
    <Box mt={2}>
      <img
        src={formData.coverPreview}
        alt="Cover Preview"
        style={{ maxWidth: '100%', maxHeight: '200px', borderRadius: '8px' }}
      />
    </Box>
  )}
  {errors.coverPhoto && <Typography color="error">Cover photo is required</Typography>}
</Box>


              {/* PDF File Upload */}
              <Box display="flex" flexDirection="column" alignItems="flex-start" mt={2}>
  <Button
    variant="contained"
    component="label"
    sx={{ backgroundColor: '#A56266', color: 'white', mb: 2 }}
  >
    Upload PDF File
    <input
      type="file"
      hidden
      accept=".pdf"
      onChange={handlePdfFileChange}
    />
  </Button>
  {formData.pdfFile && (
    <Typography color="textSecondary">
      {formData.pdfFile.name ? (
        <a href={formData.pdfFile.url} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: '#A56266' }}>
          View Current PDF: {formData.pdfFile.name}
        </a>
      ) : (
        `File selected: ${formData.pdfFile.name}`
      )}
    </Typography>
  )}
  {errors.pdfFile && <Typography color="error">PDF file is required</Typography>}
</Box>

              {/* Form Fields */}
              <TextField
                name="name"
                label="Magazine Name"
                variant="outlined"
                fullWidth
                value={formData.name}
                onChange={handleInputChange}
                sx={{ mb: 2,mt:2, backgroundColor: '#f3f4d3' }}
                error={errors.name}
                helperText={errors.name ? 'Name is required' : ''}
              />
              <TextField
                name="publicationDate"
                label="Publication Date"
                variant="outlined"
                fullWidth
                value={formData.publicationDate}
                onChange={handleInputChange}
                sx={{ mb: 2, backgroundColor: '#e0dab8' }}
                error={errors.publicationDate}
                helperText={errors.publicationDate ? 'Publication date is required' : ''}
                type="date"
                InputLabelProps={{
                  shrink: true,
                }}
              />

              {/* Submit Button */}
              <Box display="flex" justifyContent="flex-end" mt={2}>
                <Button
                  variant="contained"
                  type="submit"
                  sx={{ backgroundColor: '#A56266', color: 'white' }}
                  disabled={isLoading}
                >
                  {isEditMode ? 'Update' : 'Save'}
                </Button>
              </Box>
            </form>
            <Toaster position="top-center" reverseOrder={false} />
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
}

export default NewMagazine;
