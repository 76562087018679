import React, { useEffect, useState } from 'react';
import { Box, Button, Card, FormControl, InputLabel, MenuItem, Select, TextField, Typography, Grid, Container, CircularProgress } from '@mui/material';
import styled from '@emotion/styled';
import { useNavigate, useParams } from 'react-router-dom';
import { getCategoryById, postCategory, updateCategory } from '../../Services/AdminServices';
import toast, { Toaster } from 'react-hot-toast';
import ResponsiveDrawer from '../../Admin/Components/Drawer/Drawer';

const StyledCard = styled(Card)(({ theme }) => ({
  backgroundColor: "#fff",
  boxShadow: 'none',
  marginBottom: 20,
  width: '70%',
  '@media (max-width: 1200px)': {
    width: '70%',
  },
  '@media (max-width: 992px)': {
    width: '100%',
  },
  '@media (max-width: 768px)': {
    width: '100%',
  },
}));

function NewCategory() {
  const navigate = useNavigate();
  const { catId } = useParams();

  const [main, setMain] = useState("");
  const [category, setCategory] = useState("");
  const [categoryError, setCategoryError] = useState(false);
  const [mainError, setMainError] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (catId) {
      getCategoryById(catId)
        .then((res) => {
          const data = res?.data?.category;
          if (data) {
            setCategory(data?.name || "");
            setMain(data?.type ? data.type.charAt(0).toUpperCase() + data.type.slice(1).toLowerCase() : "");

          } else {
            console.error("No category data found");
            toast.error("Category data not found");
          }
          setLoading(false);
        })
        .catch((err) => {
          console.error("Error fetching category data:", err);
          toast.error("Error loading category");
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  }, [catId]);
  
  const handleTypeChange = (e) => {
    setMain(e.target.value);
    setMainError(false);
  };

  const handleCategoryChange = (e) => {
    setCategory(e.target.value);
    setCategoryError(false);
  };

  const saveClick = async () => {
    // Reset error states
    setMainError(false);
    setCategoryError(false);

    // Validation
    let hasError = false;
    if (!main) {
      setMainError(true);
      hasError = true;
    }
    if (!category) {
      setCategoryError(true);
      hasError = true;
    }
    if (hasError) {
      toast.error("Please fill in all required fields");
      return;
    }

    const data = {
      name: category.trim(),
      type: main.toLowerCase(),
    };

    try {
      if (catId) {
        const response = await updateCategory(catId, data);
        if (response?.data?.success) {
          toast.success("Category updated successfully");
          setTimeout(() => {
            navigate('/admin/categories');
          }, 2000);
        }
      } else {
        const response = await postCategory(data);
        if (response?.data?.success) {
          toast.success("Category created successfully");
          setTimeout(() => {
            navigate('/admin/categories');
          }, 2000);
        }
      }
    } catch (err) {
      console.error("Error saving category:", err);
      toast.error(err?.response?.data?.message || "An error occurred while saving the category");
    }
  };

  if (loading) {
    return (
      <Container maxWidth="lg" sx={{ padding: 0 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} lg={2}>
            <ResponsiveDrawer />
          </Grid>
          <Grid item xs={12} lg={10} sx={{ mt: 12 }}>
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '60vh' }}>
              <CircularProgress />
            </Box>
          </Grid>
        </Grid>
      </Container>
    );
  }

  return (
    <Container maxWidth="lg" sx={{ padding: 4 }}>
      <Grid container spacing={2}>
        <Grid item xs={12} lg={2}>
          <ResponsiveDrawer />
        </Grid>
        <Grid item xs={12} lg={10} sx={{ mt: { xs: 5, sm: 8 } }}>
       
            <Grid container alignItems="center">
              <Grid item  >
                <Typography variant="h5" gutterBottom sx={{ fontWeight: 'bold' }}>
                  {catId ? "Edit" : "New"} Category
                </Typography>
              </Grid>
            </Grid>
        

          <StyledCard>
            <Box sx={{ p: 3 }}>
              <FormControl fullWidth sx={{ mb: 5}} error={mainError}>
                <InputLabel required>Type</InputLabel>
                <Select
                  value={main}
                  label="Type *"
                  onChange={handleTypeChange}
                >
                  <MenuItem value={"News"}>News</MenuItem>
                  <MenuItem value={"Articles"}>Articles</MenuItem>
                </Select>
              </FormControl>

              <TextField
                error={categoryError}
                label="Category Name"
                placeholder="Enter category name"
                variant="outlined"
                sx={{ mb: 5 }}
                fullWidth
                required
                value={category}
                onChange={handleCategoryChange}
              />

              <Button
                variant="contained"
                sx={{
                  backgroundColor: "#A56266",
                  color: "white",
                  borderRadius: 2,
                  fontSize: 15,
                  '&:hover': {
                    backgroundColor: "#8B4F53"
                  }
                }}
                onClick={saveClick}
              >
                {catId ? "Update" : "Save"}
              </Button>
            </Box>
          </StyledCard>

          <Toaster
            position="top-center"
            reverseOrder={false}
            toastOptions={{
              success: {
                style: {
                  background: 'rgb(46, 125, 50)',
                  color: 'white'
                },
                iconTheme: {
                  primary: 'white',
                  secondary: 'rgb(46, 125, 50)',
                },
              },
              error: {
                style: {
                  background: 'rgb(211, 47, 47)',
                  color: 'white'
                },
                iconTheme: {
                  primary: 'white',
                  secondary: 'rgb(211, 47, 47)',
                },
              },
              duration: 3000,
            }}
          />
        </Grid>
      </Grid>
    </Container>
  );
}

export default NewCategory;