import { Button, Icon, InputAdornment, TextField, Typography, Grid, useMediaQuery } from '@mui/material';
import React, { useState } from 'react';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import CodeOutlinedIcon from '@mui/icons-material/CodeOutlined';
import { useNavigate, useLocation } from 'react-router-dom';
import { Toaster, toast } from 'react-hot-toast';
import logoo from '../Assets/logoo.png';
import { reset } from '../Services/AdminServices';
function Reset() {
  const navigate = useNavigate();
  const location = useLocation();
  const isDesktop = useMediaQuery('(min-width:600px)');

  const { email } = location.state || {};
  const [otpValue, setOtpValue] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [otpError, setOtpError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);

  const handleReset = async () => {
    let error = false;
  
    if (!otpValue) {
      setOtpError(true);
      error = true;
    } else {
      setOtpError(false);
    }
  
    if (!newPassword || newPassword !== confirmPassword) {
      setPasswordError(true);
      error = true;
    } else {
      setPasswordError(false);
    }
  
    if (error) return;
  
    try {
      const response = await reset({ 
        email, 
        otp: otpValue, 
        newPassword 
      });
  
      if (response.data.success) {
        toast.success(response.data.message);
        navigate("/login");
      } else {
        // Handle invalid OTP scenario
        setOtpError(true);
        toast.error(response.data.message || "Invalid OTP");
      }
    } catch (err) {
      console.error("Error resetting password:", err);
      toast.error("An error occurred. Please try again.");
    }
  };

  const forgotButton = {
    backgroundColor: '#4a148c',
    fontSize: 15,
    marginBottom: 20,
    color: 'white',
    borderRadius: 16
  };

  const textFieldStyle = {
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#4a148c',
        borderRadius: 16
      },
      '&:hover fieldset': {
        borderColor: '#4a148c'
      },
      '&.Mui-focused fieldset': {
        borderColor: '#4a148c'
      }
    }
  };

  return (
    <Grid container style={{ height: '100vh' }}>
      {isDesktop && (
        <Grid item xs={12} sm={7}>
          <img src={logoo} alt="Image1" style={{ width: '100%', height: '100%' }} />
        </Grid>
      )}
      
      <Grid item xs={12} sm={isDesktop ? 5 : 12} style={{ backgroundColor: '#F3F4F6', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <div style={{ display: 'flex', flexDirection: 'column', width: '100%', maxWidth: '400px', padding: '0 20px', background: 'white', borderRadius: '16px' }}>
          <h4 style={{ mt: 6, textAlign: 'center' }}>Reset Password</h4>
          
          <TextField
  error={otpError}
  helperText={otpError ? "Invalid OTP. Please try again." : ""}
  value={otpValue}
  onChange={(e) => {
    setOtpValue(e.target.value);
    setOtpError(false); // Clear error when user starts typing
  }}
  placeholder='Enter OTP'
  margin="dense"
  variant="outlined"
  fullWidth={true}
  sx={{ mb: 3, ...textFieldStyle }}
  InputProps={{
    startAdornment: (
      <InputAdornment position="start">
        <Icon>
          <CodeOutlinedIcon sx={{ color: '#4a148c' }} />
        </Icon>
      </InputAdornment>
    )
  }}
/>
          
          <TextField
            error={passwordError}
            type="password"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            placeholder='New Password'
            margin="dense"
            variant="outlined"
            fullWidth={true}
            sx={{ mb: 3, ...textFieldStyle }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Icon>
                    <LockOutlinedIcon sx={{ color: '#4a148c' }} />
                  </Icon>
                </InputAdornment>
              )
            }}
          />
          
          <TextField
            error={passwordError}
            type="password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            placeholder='Confirm Password'
            margin="dense"
            variant="outlined"
            fullWidth={true}
            sx={{ mb: 3, ...textFieldStyle }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Icon>
                    <LockOutlinedIcon sx={{ color: '#4a148c' }} />
                  </Icon>
                </InputAdornment>
              )
            }}
          />
          
          <Button
            type='submit'
            style={forgotButton}
            fullWidth={true}
            color='primary'
            variant='contained'
            onClick={handleReset}
          >
            RESET PASSWORD
          </Button>
        </div>
        
        <Toaster
          position="top-center"
          reverseOrder={false}
          toastOptions={{
            success: {
              style: {
                background: '#4a148c',
                color: 'white'
              },
              iconTheme: {
                primary: '#4a148c',
                secondary: 'white',
              },
            },
            error: {
              style: {
                background: 'rgb(211, 47, 47)',
                color: 'white'
              },
              iconTheme: {
                primary: 'rgb(211, 47, 47)',
                secondary: 'white',
              },
            },
          }}
        />
      </Grid>
    </Grid>
  );
}

export default Reset;