import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { Box, Button, Card, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography, IconButton, Container, Grid, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import ResponsiveDrawer from '../../Admin/Components/Drawer/Drawer';
import { useNavigate } from 'react-router-dom';
import { getAuthors, deleteAuthorById } from '../../Services/AdminServices'; 
import toast, { Toaster } from 'react-hot-toast';
import EditIcon from '@mui/icons-material/Edit';
const Item = styled(Card)(({ theme }) => ({
  backgroundColor: "#fff",
  boxShadow: 'none',
  padding: 20,
}));

const AdminAuthors = ({ ...props }) => {
  const navigate = useNavigate();
  const [authors, setAuthors] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedAuthorId, setSelectedAuthorId] = useState(null);

  useEffect(() => {
    fetchAuthors();
  }, []);

  const fetchAuthors = async () => {
    try {
      const response = await getAuthors();
      setAuthors(response?.data?.authors || []);
    } catch (error) {
      toast.error('Error fetching authors');
    }
  };

  const handleEdit = (id) => {
    navigate(`/admin/edit-author/${id}`);
  };



  const handleClick = () => {
    navigate('/admin/newauthor');
  };

  const handleDeleteClick = (authorId) => {
    setSelectedAuthorId(authorId);
    setOpenDialog(true); // Open confirmation dialog
  };

  const confirmDelete = async () => {
    try {
      const response = await deleteAuthorById(selectedAuthorId);
      toast.success(response?.data?.message);
      fetchAuthors(); 
    } catch (error) {
      toast.error('Error deleting author');
    } finally {
      setOpenDialog(false); 
      setSelectedAuthorId(null);
    }
  };

  const cancelDelete = () => {
    setOpenDialog(false);
    setSelectedAuthorId(null);
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const filteredAuthors = authors.filter(
    (author) => author.name && author.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <Container  >
    <Grid container spacing={2}>
      {/* Left Drawer Section */}
      <Grid item xs={12} sm={3} md={2} lg={2}>
        <ResponsiveDrawer {...props} />
      </Grid>

      {/* Right Content Section */}
      <Grid item xs={12} sm={10} md={10} lg={10} >
      <Box sx={{  mt: {xs:10,sm:12}, display: 'flex', alignItems: 'center', gap:{xs:22,sm:88}}}>
<Typography variant="h5" gutterBottom sx={{ fontWeight: 'bold' ,marginLeft:{xs:3,sm:2}}}>
 Authors
</Typography>
<Button
  variant="contained"
  style={{ backgroundColor: "#A56266", color: "white" }}
  onClick={handleClick}
>
  <AddIcon /> Create
</Button>
</Box>

  <TableContainer component={Item}>
            <Table sx={{ minWidth:320 }} aria-label="simple table">
            <TableHead>
  <TableRow>
    <TableCell sx={{ backgroundColor: "#caada7", color: "white", fontWeight: "bold" }}>Action</TableCell>
    <TableCell sx={{ backgroundColor: "#d18898", color: "white", fontWeight: "bold" }}>Author</TableCell>
    <TableCell sx={{ backgroundColor: "#caada7", color: "white", fontWeight: "bold" }}>Bio</TableCell>
    <TableCell sx={{ backgroundColor: "#d18898", color: "white", fontWeight: "bold" }}>Photo</TableCell>
  </TableRow>
</TableHead>
<TableBody>
  {filteredAuthors.length === 0 ? (
    <TableRow>
      <TableCell colSpan={4} align="center">No authors found</TableCell>
    </TableRow>
  ) : (
    filteredAuthors.map((author, index) => (
      <TableRow key={author._id}>
        <TableCell align="left" style={{ backgroundColor: index % 2 === 0 ? '#f3f4d3' : '#e0dab8' }}>
        {/* <IconButton title="Edit Author" onClick={() => handleEdit(author._id)}>
            <EditIcon style={{ fontSize: '16px' }} />
          </IconButton> */}




          <IconButton title="Delete Author" onClick={() => handleDeleteClick(author._id)}>
            <DeleteIcon style={{ fontSize: '16px' }} />
          </IconButton>
        </TableCell>
        <TableCell align="left" style={{ backgroundColor: index % 2 === 0 ? '#f3f4d3' : '#e0dab8' }}>
          <span style={{ fontSize: 17, fontWeight: 'bold' }}>{author.name}</span>
        </TableCell>
        <TableCell align="left" style={{ backgroundColor: index % 2 === 0 ? '#f3f4d3' : '#e0dab8' }}>
          <span style={{ fontSize: 14 }}>{author.bio || 'No bio available'}</span>
        </TableCell>
        <TableCell align="left" style={{ backgroundColor: index % 2 === 0 ? '#f3f4d3' : '#e0dab8' }}>
          {author.photo?.url ? (
            <img src={author.photo.url} alt={author.name} style={{ width: 50, height: 50, borderRadius: '50%' }} />
          ) : (
            'No photo'
          )}
        </TableCell>
      </TableRow>
    ))
  )}
</TableBody>

            </Table>
         
      

      <Dialog open={openDialog} onClose={cancelDelete}>
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <DialogContentText>Are you sure you want to delete this author? This action cannot be undone.</DialogContentText>
        </DialogContent>
       

<DialogActions>
          <Button onClick={cancelDelete} color="primary">
            Cancel
          </Button>
          <Button 
            onClick={confirmDelete}
            style={{ backgroundColor: "#A56266", color: "white" }}
            variant="contained"
            autoFocus
          >
            Delete
          </Button>
        </DialogActions>



      </Dialog>
</TableContainer>
      
          
         </Grid>
              </Grid>
        
              <Toaster position="top-center" reverseOrder={false} />
            </Container>
  );
};

export default AdminAuthors;
