import React, { useEffect, useState } from 'react';
import { 
  Box, 
  Container, 
  Typography, 
  Grid, 
  useTheme, 
  useMediaQuery, 
  Snackbar,
  Alert
} from '@mui/material';
import { useParams } from 'react-router-dom';
import { getNewsarticlesById } from '../../../Services/AdminServices';
import { WhatsappIcon, WhatsappShareButton } from 'react-share';

const Newsdetailsharon = () => {
  const { slug } = useParams();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [blog, setBlog] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success'
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const fetchArticle = async () => {
      if (!slug) {
        setError('No article slug provided.');
        setLoading(false);
        return;
      }

      try {
        // Remove the decoding since the slug from useParams is already decoded
        const response = await getNewsarticlesById(slug);
        
        if (response?.data?.success) {
          setBlog(response.data.article);
        } else {
          setError(response?.data?.message || 'Article not found.');
        }
      } catch (err) {
        console.error('Error fetching article:', err);
        setError(err?.response?.data?.message || 'Failed to fetch article.');
      } finally {
        setLoading(false);
      }
    };

    fetchArticle();
  }, [slug]);

  const getShareUrl = () => {
    if (!blog?.slug) return '';
    const baseUrl = window.location.origin;
    return `${baseUrl}/news-articles/getNewsBySlug/${blog.slug}`;
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbar({ ...snackbar, open: false });
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
        <Typography>Loading...</Typography>
      </Box>
    );
  }

  if (error) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
        <Typography color="error">{error}</Typography>
      </Box>
    );
  }

  if (!blog) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
        <Typography>No article found.</Typography>
      </Box>
    );
  }

  const authorDisplay = blog.author?.name || blog.author || 'Unknown Author';

  return (
    <Box sx={{ backgroundColor: '#f8f8f8', overflowX: 'hidden' }}>
      <Container sx={{ mt: { xs: 1, sm: 6 }, ml: { xs: 1, sm: 10 } }}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Box
              sx={{
                backgroundColor: 'white',
                borderRadius: 2,
                boxShadow: '0 4px 20px rgba(0,0,0,0.1)',
                p: 3,
                position: 'relative',
              }}
            >
              <Box sx={{ 
                position: 'absolute', 
                top: 16, 
                right: 16,
                display: 'flex',
                gap: '10px'
              }}>
                <WhatsappShareButton
                  url={getShareUrl()}
                  title={blog?.title}
                >
                  <WhatsappIcon size={32} round />
                </WhatsappShareButton>
              </Box>

              <Typography
                variant="h4"
                sx={{
                  mb: 1,
                  textAlign: 'left',
                  fontWeight: 500,
                  fontSize: { xs: '1.3rem', md: '2rem' },
                }}
              >
                {blog.title}
              </Typography>
              <Typography variant="body2" sx={{ color: '#555' }}>
                by {authorDisplay}
              </Typography>

              <Box
                className="blog-content"
                lang="ml"
                sx={{
                  '& img': {
                    maxWidth: '100%',
                    height: 'auto',
                    borderRadius: 1,
                    my: 2,
                  },
                  '& p': {
                    lineHeight: 1.6,
                    fontSize: '1.1rem',
                    marginBottom: '1rem',
                    wordSpacing: '0.05em',
                    hyphens: 'auto',
                    textAlign: isMobile ? 'left' : 'justify',
                    overflowWrap: 'break-word',
                    wordBreak: 'break-word',
                  },
                  fontFamily: "'Noto Sans Malayalam', sans-serif",
                  overflowX: 'hidden',
                }}
              >
                <div dangerouslySetInnerHTML={{ __html: blog.content }} />
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Container>

      <Snackbar 
        open={snackbar.open} 
        autoHideDuration={6000} 
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      >
        <Alert 
          onClose={handleCloseSnackbar} 
          severity={snackbar.severity}
          sx={{ width: '100%' }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default Newsdetailsharon;