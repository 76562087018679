import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { Box, Button, Card, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography, IconButton, Container, Grid } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import ResponsiveDrawer from '../../Admin/Components/Drawer/Drawer'; 
import { useNavigate } from 'react-router-dom';
import { getAllCourses, deleteCourseById } from '../../Services/AdminServices'; 
import toast, { Toaster } from 'react-hot-toast';

const Item = styled(Card)(({ theme }) => ({
  backgroundColor: "#fff",
  boxShadow: 'none',
  padding: 20,
}));

const AdminCourses = ({ ...props }) => {
  const navigate = useNavigate();
  const [courses, setCourses] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');

  useEffect(() => {
    fetchCourses();
  }, []);

  const fetchCourses = async () => {
    try {
      const response = await getAllCourses();
      setCourses(response?.data?.courses || []);
    } catch (error) {
      toast.error('Error fetching courses');
    }
  };

  const handleClick = () => {
    navigate('/admin/newcourse');
  };

  const handleDelete = async (courseId) => {
    try {
      const response = await deleteCourseById(courseId);
      toast.success(response?.data?.message);
      fetchCourses();
    } catch (error) {
      toast.error('Error deleting course');
    }
  };

  const handleEdit = (courseId) => {
    navigate(`/admin/newcourses/${courseId}`);
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  // Updated filtering to use 'name' instead of 'courseName'
  const filteredCourses = courses.filter(
    (course) => course.name && course.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <Container  >
    <Grid container spacing={2}>
      {/* Left Drawer Section */}
      <Grid item xs={12} sm={3} md={2} lg={2}>
        <ResponsiveDrawer {...props} />
      </Grid>

      {/* Right Content Section */}
      <Grid item xs={12} sm={10} md={10} lg={10} >
      <Box sx={{  mt: {xs:10,sm:12}, display: 'flex', alignItems: 'center', gap:{xs:22,sm:88}}}>
<Typography variant="h5" gutterBottom sx={{ fontWeight: 'bold' ,marginLeft:{xs:3,sm:2}}}>
  Courses
</Typography>
<Button
  variant="contained"
  style={{ backgroundColor: "#A56266", color: "white" }}
  onClick={handleClick}
>
  <AddIcon /> Create
</Button>
</Box>
<Box sx={{ marginRight: { xs: -2, sm: 0 } }}>
          <TableContainer component={Item} sx={{ padding: '16px' }} >
            <Table sx={{ minWidth: 320 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell sx={{ backgroundColor: "#caada7", color: "white", fontWeight: "bold" }}>Action</TableCell>
                  <TableCell sx={{ backgroundColor: "#d18898", color: "white", fontWeight: "bold" }}>Course Name</TableCell>
                  <TableCell sx={{ backgroundColor: "#A56266", color: "white", fontWeight: "bold" }}>Teacher Name</TableCell>
                  <TableCell sx={{ backgroundColor: "#d18898", color: "white", fontWeight: "bold" }}>Date & Time</TableCell>
                  <TableCell sx={{ backgroundColor: "#A56266", color: "white", fontWeight: "bold" }}>Google Classroom</TableCell>
                  <TableCell sx={{ backgroundColor: "#caada7", color: "white", fontWeight: "bold" }}>Description</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredCourses.length === 0 ? (
                  <TableRow>
                    <TableCell colSpan={6} align="center">No courses found</TableCell>
                  </TableRow>
                ) : (
                  filteredCourses.map((course, index) => (
                    <TableRow key={course._id}>
                      <TableCell align="left" style={{ backgroundColor: index % 2 === 0 ? '#f3f4d3' : '#e0dab8' }}>
                        {/* <IconButton title="Edit course" onClick={() => handleEdit(course._id)}>
                          <EditIcon style={{ fontSize: '16px' }} />
                        </IconButton> */}
                        <IconButton title="Delete course" onClick={() => handleDelete(course._id)}>
                          <DeleteIcon style={{ fontSize: '16px' }} />
                        </IconButton>
                      </TableCell>
                      <TableCell align="left" style={{ backgroundColor: index % 2 === 0 ? '#f3f4d3' : '#e0dab8' }}>
                        <span style={{ fontSize: 17, fontWeight: 'bold' }}>{course.name}</span>
                      </TableCell>
                      <TableCell align="left" style={{ backgroundColor: index % 2 === 0 ? '#f3f4d3' : '#e0dab8' }}>
                        <span style={{ fontWeight: 600 }}>{course.teacherName}</span>
                      </TableCell>
                      <TableCell align="left" style={{ backgroundColor: index % 2 === 0 ? '#f3f4d3' : '#e0dab8' }}>
                        <span style={{ fontWeight: 600 }}>{new Date(course.dateTime).toLocaleString()}</span>
                      </TableCell>
                      <TableCell align="left" style={{ backgroundColor: index % 2 === 0 ? '#f3f4d3' : '#e0dab8' }}>
                        <a href={course.googleClassroomLink} target="_blank" rel="noopener noreferrer">Link</a>
                      </TableCell>
                      <TableCell align="left" style={{ backgroundColor: index % 2 === 0 ? '#f3f4d3' : '#e0dab8' }}>
                        <span style={{ fontWeight: 600, fontSize: 14 }}>{course.description}</span>
                      </TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </TableContainer>
       </Box>
      </Grid>
              </Grid>
        
              <Toaster position="top-center" reverseOrder={false} />
            </Container>
  );
};

export default AdminCourses;
