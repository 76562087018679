import React,{ useState } from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import BookIcon from '@mui/icons-material/Book';
import LibraryAddIcon from '@mui/icons-material/LibraryAdd';
import CssBaseline from '@mui/material/CssBaseline';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';

import MenuIcon from '@mui/icons-material/Menu';
import HomeIcon from '@mui/icons-material/Home';
import DynamicFeedIcon from '@mui/icons-material/DynamicFeed';
import ArticleIcon from '@mui/icons-material/Article';
import AdsClickIcon from '@mui/icons-material/AdsClick';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { ListItem, ListItemButton, ListItemIcon, ListItemText, Dialog, DialogActions, DialogContent, DialogTitle, Button } from '@mui/material';
import PublishIcon from '@mui/icons-material/Publish';
import AddIcon from '@mui/icons-material/Add';
import { Avatar, Collapse, Toolbar } from '@mui/material';
import logo from '../../../Assets/logo1.png';
import LogoutIcon from '@mui/icons-material/Logout';
import { Link, useNavigate } from 'react-router-dom';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
function ResponsiveDrawer(props) {
    const drawerWidth = 240;
    const { window } = props;
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const [newsMenu, setNewsMenu] = React.useState(false);
    const [openDialog, setOpenDialog] = useState(false);
    const navigate = useNavigate();
    const handleClickOpen = () => {
      setOpenDialog(true); 
    };
  
    const handleClose = () => {
      setOpenDialog(false); 
    };
  
    const handleLogout = () => {
     setOpenDialog(false);
      navigate('/');
      
     
    };
  





    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const handleNewsClick = () => {
        setNewsMenu(!newsMenu);
    };

    const drawer = (
        <div>
             <img src={logo} loading="lazy" alt="logo" />
             <List sx={{ px: 1 }}>
      <ListItem disablePadding>
        <ListItemButton component={Link} to="/admin" sx={{ color: 'white' }}>
          <ListItemIcon sx={{ color: 'white' }}>
            <HomeIcon />
          </ListItemIcon>
          <ListItemText primary={"Home"} sx={{ color: 'white' }} />
        </ListItemButton>
      </ListItem>
      <ListItem disablePadding>
        <ListItemButton component={Link} to="/admin/categories" sx={{ color: 'white' }}>
          <ListItemIcon sx={{ color: 'white' }}>
            <DynamicFeedIcon />
          </ListItemIcon>
          <ListItemText primary={"Category"} sx={{ color: 'white' }} />
        </ListItemButton>
      </ListItem>
      <ListItemButton onClick={handleNewsClick} sx={{ color: 'white' }}>
        <ListItemIcon sx={{ color: 'white' }}>
          <ArticleIcon />
        </ListItemIcon>
        <ListItemText primary="News" sx={{ color: 'white' }} />
        {newsMenu ? <KeyboardArrowDownIcon sx={{ color: 'white' }} /> : <KeyboardArrowRightIcon sx={{ color: 'white' }} />}
      </ListItemButton>
      <Collapse in={newsMenu} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <ListItemButton component={Link} to="/admin/create-news" sx={{ pl: 4, color: 'white' }}>
            <ListItemIcon sx={{ color: 'white' }}>
              <AddIcon />
            </ListItemIcon>
            <ListItemText primary="Create News" sx={{ color: 'white' }} />
          </ListItemButton>
          {/* <ListItemButton component={Link} to="/admin/drafted-news" sx={{ pl: 4, color: 'white' }}>
            <ListItemIcon sx={{ color: 'white' }}>
              <DraftsIcon />
            </ListItemIcon>
            <ListItemText primary="Drafted News" sx={{ color: 'white' }} />
          </ListItemButton> */}
          <ListItemButton component={Link} to="/admin/publish" sx={{ pl: 4, color: 'white' }}>
            <ListItemIcon sx={{ color: 'white' }}>
              <PublishIcon />
            </ListItemIcon>
            <ListItemText primary="Published News" sx={{ color: 'white' }} />
          </ListItemButton>
        </List>
      </Collapse>
      {/* <ListItem disablePadding>
        <ListItemButton component={Link} to="/admin/courses" sx={{ color: 'white' }}>
        <ListItemIcon sx={{ color: 'white' }}>
  <BookIcon />
</ListItemIcon>
          <ListItemText primary={"Courses"} sx={{ color: 'white' }} />
        </ListItemButton>
      </ListItem> */}

      <ListItem disablePadding>
        <ListItemButton component={Link} to="/admin/authors" sx={{ color: 'white' }}>
          <ListItemIcon sx={{ color: 'white' }}>
            <CloudUploadIcon />
          </ListItemIcon>
          <ListItemText primary={"Authors"} sx={{ color: 'white' }} />
        </ListItemButton>
      </ListItem>



      <ListItem disablePadding>
        <ListItemButton component={Link} to="/admin/publications" sx={{ color: 'white' }}>
        <ListItemIcon sx={{ color: 'white' }}>
  <LibraryAddIcon />
</ListItemIcon>
          <ListItemText primary={"Publications"} sx={{ color: 'white' }} />
        </ListItemButton>
      </ListItem>

      <ListItem disablePadding>
        <ListItemButton component={Link} to="/admin/magazine" sx={{ color: 'white' }}>
          <ListItemIcon sx={{ color: 'white' }}>
            <AdsClickIcon />
          </ListItemIcon>
          <ListItemText primary={"Magazine"} sx={{ color: 'white' }} />
        </ListItemButton>
      </ListItem>
      <ListItem disablePadding>
  <ListItemButton component={Link} to="/admin/profile" sx={{ color: 'white' }}>
    <ListItemIcon sx={{ color: 'white' }}>
      <AccountCircleIcon />
    </ListItemIcon>
    <ListItemText primary={"Profile"} sx={{ color: 'white' }} />
  </ListItemButton>
</ListItem>
<ListItem disablePadding>
        <ListItemButton onClick={handleClickOpen} sx={{ color: 'white' }}>
          <ListItemIcon sx={{ color: 'white' }}>
            <LogoutIcon />
          </ListItemIcon>
          <ListItemText primary={"LogOut"} sx={{ color: 'white' }} />
        </ListItemButton>
      </ListItem>
    </List>

        </div>
    );

    const container = window !== undefined ? () => window().document.body : undefined;

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, backgroundColor: "#ffffff" }}>
                <Toolbar style={{ justifyContent: 'space-between' }}>
                    <img src={logo} loading="lazy" alt="logo" style={{ marginLeft: '-30px' }} />
                    
                    
                    <IconButton color="inherit" aria-label="open drawer" edge="start" onClick={handleDrawerToggle} sx={{ display: { xs: 'block', sm: 'none' } }}>
                        <MenuIcon sx={{ color: '#80868D' }} />
                    </IconButton>
                </Toolbar>
            </AppBar>
            <Box component="nav" sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 }, backgroundColor: "#89A8AE" }} aria-label="mailbox folders">
                <Drawer
                    container={container}
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    sx={{
                        display: { xs: 'block', sm: 'none' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth, backgroundColor: "#89A8AE", position: 'absolute', top: 0, zIndex: 1300 },
                    }}
                >
                    {drawer}
                </Drawer>
                <Drawer
                    variant="permanent"
                    sx={{
                        display: { xs: 'none', sm: 'block' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth, backgroundColor: "#89A8AE" },
                    }}
                    open
                >
                    {drawer}
                </Drawer>
            </Box>
             {/* Confirmation Dialog */}
      <Dialog open={openDialog} onClose={handleClose}>
        <DialogTitle>Confirm Logout</DialogTitle>
        <DialogContent>
          Are you sure you want to log out?
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleLogout} color="primary">
            Logout
          </Button>
        </DialogActions>
      </Dialog>
        </Box>
    );
}

ResponsiveDrawer.propTypes = {
    window: PropTypes.func,
};

export default ResponsiveDrawer;
