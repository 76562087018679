import React, { useEffect, useState } from 'react';
import { Grid, Typography, Box, Button, Container, Divider } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { styled } from '@mui/system';
import aboutdetail from '../../../Assets/wallpaper.jpg';
import Magazine from '../HomePage/Magazine';
import { getBooks, getBooksById, getAuthors } from '../../../Services/AdminServices';

const HeroSection = styled(Box)(({ theme }) => ({
  position: 'relative',
  backgroundImage: `url(${aboutdetail})`,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  height: '100px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: 'white',
  textAlign: 'center',
  padding: theme.spacing(8, 0),
}));

const HeroContent = styled(Box)(({ theme }) => ({
  zIndex: 1,
  maxWidth: '800px',
  padding: theme.spacing(4),
}));

const BookCard = ({ book, onViewDetails }) => (
  <Box
    sx={{
      display: 'flex',
      flexDirection: 'column',
      borderRadius: 2,
      backgroundColor: '#eceff1',
      padding: theme => theme.spacing(4),
      maxWidth: 600,
      width: '100%',
      boxShadow: 1,
      height: 'auto',
      ml: { xs: 2 },
      mt: { xs: -2 },
    }}
  >
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <img
          src={book.coverPhoto?.url}
          alt={`Book cover of ${book.name}`}
          style={{
            width: '100%',
            height: '200px',
            objectFit: 'contain',
            borderRadius: 4,
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h6" component="div" sx={{ fontWeight: 'bold', color: '#333', fontSize: '1rem' }}>
          {book.name}
        </Typography>
        <Typography variant="body2" sx={{ color: '#37474f', fontSize: '0.75rem', marginBottom: 1, mt: 1 }}>
          By <strong>{book.authorName || 'Unknown Author'}</strong>
        </Typography>
        <Typography variant="body2" sx={{ color: '#424242', fontSize: '0.8rem' }}>
          {book.shortDescription}
        </Typography>
        <Typography variant="body2" sx={{ fontWeight: 'bold', color: '#37474f', marginTop: 1, fontSize: '0.875rem' }}>
          ₹{book.price}
        </Typography>

        <Button
          variant="contained"
          sx={{
            mt: 2,
            backgroundColor: '#AD62AA',
            color: 'white',
            fontSize: '0.75rem',
            padding: '6px 16px',
            '&:hover': { backgroundColor: '#F78C6B' },
          }}
          onClick={() => onViewDetails(book)}
        >
          View Details
        </Button>
      </Grid>
    </Grid>
    <Divider sx={{ mt: 2 }} />
  </Box>
);

function Publications() {
  const [books, setBooks] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchPublications = async () => {
      try {
        const [booksResponse, authorsResponse] = await Promise.all([getBooks(), getAuthors()]);
        const authors = Object.fromEntries(authorsResponse.data.authors.map(author => [author._id, author.name]));
        const books = booksResponse.data.books.map(book => ({
          ...book,
          authorName: authors[book.author] || 'Unknown Author',
        }));
        setBooks(books);
      } catch (err) {
        console.error('Error fetching publications:', err);
        setError('Failed to load books');
      } finally {
        setLoading(false);
      }
    };

    fetchPublications();
  }, []);

  const handleViewDetails = async (book) => {
    try {
      const response = await getBooksById(book._id);

      if (response.data && response.data.success) {
        const fullBookDetails = {
          ...book,
          ...response.data.book,
          userDetails: response.data.user,
        };

        navigate('/download', {
          state: {
            bookDetails: fullBookDetails,
            userDetails: response.data.user,
          },
        });
      } else {
        console.error('Failed to fetch complete book details');
        navigate('/download', {
          state: { bookDetails: book },
        });
      }
    } catch (error) {
      console.error('Failed to fetch book details:', error);
      navigate('/download', { state: { bookDetails: book } });
    }
  };

  return (
    <div>
      <Box sx={{ mt: { xs: 0, lg: 3 } }}>
        <HeroSection>
          <HeroContent>
            <Typography
              variant="h4"
              sx={{ fontWeight: 600, color: '#AD62AA', fontSize: { xs: '1.5rem', md: '2.5rem' }, mt: 2 }}
            >
              Publications
            </Typography>
            <Typography variant="body1" sx={{ fontSize: '1rem', color: '#AD62AA' }}>
              Explore a wide range of books for your knowledge and spiritual growth.
            </Typography>
          </HeroContent>
        </HeroSection>

        <Container maxWidth="lg" sx={{ mt: 4, padding: { xs: 4 } }}>
          {loading ? (
            <Typography variant="h6" sx={{ textAlign: 'center', color: '#AD62AA' }}>
              Loading books...
            </Typography>
          ) : error ? (
            <Typography variant="h6" sx={{ textAlign: 'center', color: 'red' }}>
              {error}
            </Typography>
          ) : books.length === 0 ? (
            <Typography variant="h6" sx={{ textAlign: 'center', color: '#AD62AA' }}>
              No books available.
            </Typography>
          ) : (
            <Grid container spacing={4}>
              {books.map((book, index) => (
                <Grid item xs={12} sm={6} md={4} key={index}>
                  <BookCard book={book} onViewDetails={handleViewDetails} />
                </Grid>
              ))}
            </Grid>
          )}
        </Container>
      </Box>

      <Box sx={{ mb: 0, ml: 0, mt: 2 }}>
        <Magazine />
      </Box>
    </div>
  );
}

export default Publications;
