import React, { useState, useEffect } from 'react';
import { Container, TextField, Button, Typography, Box, Grid, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import ResponsiveDrawer from '../Components/Drawer/Drawer';
import { Toaster, toast } from 'react-hot-toast';
import { postBooks, getBooksById, updateBooks, getAuthors } from '../../Services/AdminServices';
import { useNavigate, useParams } from 'react-router-dom';

function NewPublication(props) {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const { pId } = useParams(); // Change from 'id' to 'pId' to match the URL parameter
  const isEditMode = Boolean(pId); // Determine if it's edit mode based on pId presence
  const [authors, setAuthors] = useState([]);
  const [selectedAuthor, setSelectedAuthor] = useState('');
  const [authorLoading, setAuthorLoading] = useState(false);

  const [formData, setFormData] = useState({
    title: '',
    author: '',
    description: '',
    price: '',
    status: 'published', // Default status
    image: null,
    bookFile: null,
    imagePreview: null, // For displaying the cover photo
    bookUrl: null, // For displaying the book file
  });

  const [errors, setErrors] = useState({
    title: false,
    author: false,
    price: false,
    status: false,
    image: false,
    bookFile: false,
  });

  // Fetch existing data in edit mode
  useEffect(() => {
    if (isEditMode) {
      const fetchBookDetails = async () => {
        try {
          const response = await getBooksById(pId);
          const fetchedBook = response.data?.book;
  
          if (fetchedBook) {
            setFormData({
              title: fetchedBook.name || '',
              author: fetchedBook.author || '',
              description: fetchedBook.shortDescription || '',
              price: fetchedBook.price || '',
              status: fetchedBook.status || 'published',
              image: null,
              bookFile: null,
              imagePreview: fetchedBook.coverPhoto?.url || '',
              bookUrl: fetchedBook.bookurl?.url || '',
            });
  
            // Prefill selected author
            setSelectedAuthor(fetchedBook.author); // Use the author ID directly
          }
        } catch (error) {
          console.error('Failed to fetch book details:', error);
          toast.error('Unable to load publication details');
        }
      };
  
      fetchBookDetails();
    }
  }, [isEditMode, pId]);
  

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
    setErrors((prev) => ({
      ...prev,
      [name]: false,
    }));
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setFormData((prev) => ({
        ...prev,
        image: file,
        imagePreview: URL.createObjectURL(file),
      }));
      setErrors((prev) => ({
        ...prev,
        image: false,
      }));
    }
  };

  const handleBookFileChange = (e) => {
    const file = e.target.files[0];
    const maxSize = 2 * 1024 * 1024; // 2MB limit
    const allowedFileTypes = ['application/pdf', 'application/epub+zip', 'application/x-mobipocket-ebook'];

    if (file && file.size > maxSize) {
      toast.error('File size exceeds 2MB. Please upload a smaller file.');
      setErrors((prev) => ({
        ...prev,
        bookFile: true,
      }));
      return;
    }

    if (file && allowedFileTypes.includes(file.type)) {
      setFormData((prev) => ({
        ...prev,
        bookFile: file,
      }));
      setErrors((prev) => ({
        ...prev,
        bookFile: false,
      }));
    } else {
      toast.error('Please upload a valid book file (PDF, EPUB, MOBI).');
      setErrors((prev) => ({
        ...prev,
        bookFile: true,
      }));
    }
  };

  useEffect(() => {
    const fetchAuthors = async () => {
      try {
        setAuthorLoading(true);
        const response = await getAuthors();
        setAuthors(response?.data?.authors || []);
      } catch (error) {
        console.error('Error fetching authors:', error);
        toast.error('Error fetching authors');
      } finally {
        setAuthorLoading(false);
      }
    };
    fetchAuthors();
  }, []);

  const validateForm = () => {
    const newErrors = {
      title: !formData.title,
      author: !selectedAuthor,
      price: !formData.price,
      status: !formData.status,
      image: isEditMode ? false : !formData.image,
      bookFile: isEditMode ? false : !formData.bookFile,
    };
    setErrors(newErrors);
    return !Object.values(newErrors).some((error) => error);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      toast.error('Please fill in all required fields');
      return;
    }

    setIsLoading(true);
    const submitFormData = new FormData();
    submitFormData.append('name', formData.title);
    submitFormData.append('author', selectedAuthor);
    submitFormData.append('shortDescription', formData.description);
    submitFormData.append('price', formData.price);
    submitFormData.append('status', formData.status);
    if (formData.image) submitFormData.append('coverPhoto', formData.image);
    if (formData.bookFile) submitFormData.append('bookFile', formData.bookFile);

    try {
      const loadingToast = toast.loading(isEditMode ? 'Updating publication...' : 'Creating publication...');
      const response = isEditMode
        ? await updateBooks(pId, submitFormData)
        : await postBooks(submitFormData);

      if (response?.data?.success) {
        toast.dismiss(loadingToast);
        toast.success(isEditMode ? 'Publication updated successfully!' : 'Publication created successfully!');
        navigate('/admin/publications');
      }
    } catch (error) {
      toast.error(error.response?.data?.message || 'Failed to process the request');
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <Container maxWidth="lg" sx={{ padding: 0 }}>
      <Grid container spacing={2}>
        <Grid item xs={12} lg={2}>
          <ResponsiveDrawer {...props} />
        </Grid>
        <Grid item xs={12} lg={10} sx={{ mt: { xs: 4, sm: 10 } }}>
          <Box sx={{ padding: 4, height: '100%' }}>
            <Typography variant="h5" gutterBottom sx={{ fontWeight: 'bold' }}>
              {isEditMode ? 'Edit Publication' : 'Add New Publication'}
            </Typography>
            <form onSubmit={handleSubmit} encType="multipart/form-data">
              {/* Image Upload */}
              <Box display="flex" flexDirection="column" alignItems="flex-start" mt={2}>
                <Button
                  variant="contained"
                  component="label"
                  sx={{ backgroundColor: '#A56266', color: 'white', mb: 2 }}
                >
                  Upload Cover Photo
                  <input
                    type="file"
                    hidden
                    accept="image/*"
                    onChange={handleImageChange}
                  />
                </Button>
                {formData.imagePreview && (
                  <Box mt={2}>
                    <img
                      src={formData.imagePreview}
                      alt="Cover Preview"
                      style={{ maxWidth: '100%', maxHeight: '200px', borderRadius: '8px' }}
                    />
                  </Box>
                )}
                {errors.image && <Typography color="error">Cover photo is required</Typography>}
              </Box>

              {/* Book File Upload */}
              <Box display="flex" flexDirection="column" alignItems="flex-start" mt={2}>
                <Button
                  variant="contained"
                  component="label"
                  sx={{ backgroundColor: '#A56266', color: 'white', mb: 2 }}
                >
                  Upload Book File
                  <input type="file" hidden accept=".pdf,.epub,.mobi" onChange={handleBookFileChange} />
                </Button>
                {formData.bookFile ? (
                  <Typography>File selected: {formData.bookFile.name}</Typography>
                ) : (
                  isEditMode && <Typography>Current file: <a href={formData.bookUrl} target="_blank" rel="noopener noreferrer"  style={{ textDecoration: 'none', color: '#A56266' }}>View File</a></Typography>
                )}
                {errors.bookFile && <Typography color="error">Book file is required or too large</Typography>}
              </Box>

              {/* Form Fields */}
              <TextField
                name="title"
                label="Title"
                variant="outlined"
                fullWidth
                value={formData.title}
                onChange={handleInputChange}
                sx={{ mb: 2,mt:2, backgroundColor: '#f3f4d3' }}
                error={errors.title}
                helperText={errors.title ? 'Title is required' : ''}
              />

              <FormControl fullWidth sx={{ mb: 2 }} error={errors.author}>
                <InputLabel>Author</InputLabel>
                <Select
                  value={selectedAuthor}
                  onChange={(e) => {
                    setSelectedAuthor(e.target.value);
                    setErrors(prev => ({ ...prev, author: false })); // Clear errors on change
                  }}
                  label="Author"
                  disabled={authorLoading}
                  renderValue={(selected) => {
                    const author = authors.find(a => a._id === selected);
                    return author ? author.name : 'Select an Author';
                  }}
                >
                  {authorLoading ? (
                    <MenuItem disabled>Loading authors...</MenuItem>
                  ) : authors.length === 0 ? (
                    <MenuItem disabled>No authors available</MenuItem>
                  ) : (
                    authors.map((author) => (
                      <MenuItem key={author._id} value={author._id}>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                          <Box>
                            <Typography>{author.name}</Typography>
                          </Box>
                        </Box>
                      </MenuItem>
                    ))
                  )}
                </Select>
                {errors.author && <Typography color="error">Author is required</Typography>}
              </FormControl>

              <TextField
                name="description"
                label="Description"
                variant="outlined"
                fullWidth
                multiline
                rows={4}
                value={formData.description}
                onChange={handleInputChange}
                sx={{ mb: 2, backgroundColor: '#f3f4d3' }}
              />

              <TextField
                name="price"
                label="Price"
                variant="outlined"
                fullWidth
                value={formData.price}
                onChange={(e) => {
                  const value = e.target.value;
                  // Allow only numbers and decimals
                  if (/^\d*\.?\d*$/.test(value)) {
                    setFormData((prev) => ({
                      ...prev,
                      price: value,
                    }));
                    setErrors((prev) => ({
                      ...prev,
                      price: false,
                    }));
                  }
                }}
                sx={{ mb: 2, backgroundColor: '#e0dab8' }}
                error={errors.price}
                helperText={errors.price ? 'Price is required and must be a number' : ''}
              />

              {/* Status Field */}
              <FormControl fullWidth sx={{ mb: 2, backgroundColor: '#f3f4d3' }}>
                <InputLabel>Status</InputLabel>
                <Select
                  name="status"
                  value={formData.status}
                  label="Status"
                  onChange={handleInputChange}
                >
                  <MenuItem value="published">Published</MenuItem>
                  <MenuItem value="pre-publication">Pre-publication</MenuItem>
                </Select>
              </FormControl>

              {/* Submit Button */}
              <Box display="flex" justifyContent="flex-end" mt={2}>
                <Button
                  variant="contained"
                  type="submit"
                  sx={{ backgroundColor: '#A56266', color: 'white' }}
                  disabled={isLoading}
                >
                  {isEditMode ? 'Update' : 'Save'}
                </Button>
              </Box>
            </form>
            <Toaster position="top-center" reverseOrder={false} />
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
}

export default NewPublication;
