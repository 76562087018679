
import React, { useEffect, useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  TextField,
  Box,
  Grid,
  Container,
  Typography,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { getAllPayments } from "../../Services/AdminServices";
import ResponsiveDrawer from "../Components/Drawer/Drawer";

const AdminPayment = () => {
  const [payments, setPayments] = useState([]);
  const [error, setError] = useState("");

  // Fetch payments data on component mount
  useEffect(() => {
    getAllPayments()
      .then((response) => {
        setPayments(response.data); // Assuming `response.data` is the array of payments
      })
      .catch((err) => {
        setError(err.message || "Failed to fetch payments");
      });
  }, []);

  return (
    <Container maxWidth="lg" sx={{ padding: 0 }}>
      <Grid container spacing={2}>
        {/* Drawer Section */}
        <Grid item xs={12} md={2} sx={{ zIndex: 1 }}>
          <ResponsiveDrawer />
        </Grid>

        {/* Main Content Section */}
        <Grid item xs={12} md={10}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mb: 2,
              flexWrap: "wrap",
            }}
          >
            <Typography variant="h4" component="h2">
              Payments
            </Typography>
            <Box sx={{ display: "flex", gap: 1, flexWrap: "wrap" }}>
              <TextField
                variant="outlined"
                placeholder="Search..."
                size="small"
                sx={{ backgroundColor: "#fff" }}
              />
            </Box>
          </Box>

          {/* Display Error */}
          {error && <Typography color="error">{error}</Typography>}

          {/* Payments Table */}
          <TableContainer component={Paper} sx={{ overflowX: "auto" }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ backgroundColor: "#c9aeae", fontWeight: "bold" }}>
                    Book
                  </TableCell>
                  <TableCell sx={{ backgroundColor: "#c9aeae", fontWeight: "bold" }}>
                    WhatsApp Number
                  </TableCell>
                  <TableCell sx={{ backgroundColor: "#c9aeae", fontWeight: "bold" }}>
                    Status
                  </TableCell>
                  <TableCell sx={{ backgroundColor: "#c9aeae", fontWeight: "bold" }}>
                    Download Link
                  </TableCell>
                  <TableCell sx={{ backgroundColor: "#c9aeae", fontWeight: "bold" }}>
                    Date
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {payments.map((payment, index) => (
                  <TableRow
                    key={index}
                    sx={{
                      backgroundColor: index % 2 === 0 ? "#f9f9d9" : "#eae8b9",
                    }}
                  >
                    <TableCell>{payment.bookId}</TableCell>
                    <TableCell>{payment.whatsappNumber}</TableCell>
                    <TableCell>{payment.paymentStatus}</TableCell>
                    <TableCell>
                      {payment.downloadLink ? (
                        <a href={payment.downloadLink} target="_blank" rel="noopener noreferrer">
                          Download
                        </a>
                      ) : (
                        "N/A"
                      )}
                    </TableCell>
                    <TableCell>{new Date(payment.createdAt).toLocaleString()}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>
    </Container>
  );
};

export default AdminPayment;
