import { api } from "./api";

// Auth
export const register = (data) => {
    return api.post("/users/register", data);
};

export const verify = (id, data) => {
    return api.post(`/users/verify/${id}`, data);
};

export const login = (data) => {
    return api.post("/users/login", data);
};

export const forgot = (data) => {
    return api.post("/users/forgetpassword", data);
};

export const reset = (data) => {
    return api.put("/users/resetpassword", data);
};

export const logout = () => {
    return api.get("/users/logout");
};

export const getCurrentUser = () => {
    return api.get("/users/getMyProfile");
};

export const updateProfile = (data) => {
  return api.put('/users/updateprofile', data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

//category
export const getAllCategories = () => {
    return api.get("/categories/getall"); 
  };
  
  export const getCategoryById = (id) => {
    return api.get(`/categories/getCategoryById/${id}`); 
  };
  
  export const postCategory = (data) => {
    return api.post("/categories/create", data);
};
  export const updateCategory = (id, data) => {
    return api.patch(`/categories/update/${id}`, data);
  };
  
  export const deleteCategoryById = (id) => {
    return api.delete(`/categories/delete/${id}`); 
  };
//courses
export const getAllCourses = () => {
  return api.get("/courses/getall"); 
};

export const getCourseById = (id) => {
  return api.get(`/courses/getCourseById/${id}`); 
};

export const postCourse = (data) => {
  return api.post("/courses/create", data);
};
export const updateCourse = (id, data) => {
  return api.put(`/courses/update/${id}`, data); 
};

export const deleteCourseById = (id) => {
  return api.delete(`/courses/delete/${id}`); 
};

//magazine
export const getAllMagazines = () => {
  return api.get("/magazines/getall"); 
};

export const getMagazineById = (id) => {
  return api.get(`/magazines/getMagazineById/${id}`); 
};

export const postMagazine = (data) => {
  console.log(data);
  return api.post("/magazines/create", data, {
    headers: {
      "Content-Type": "multipart/form-data", // Specify the content type
    },
  });
};

export const updateMagazine = (id, data) => {
  return api.patch(`/magazines/update/${id}`, data); 
};

export const deleteMagazineById = (id) => {
  return api.delete(`/magazines/delete/${id}`); 
};
//publication
export const getBooks = () => {
  return api.get("/books/getall"); 
};

export const getBooksById = (id) => {
  return api.get(`/books/getBookById/${id}`); 
};

export const postBooks = (data) => {
  return api.post("/books/create", data);
};
export const updateBooks = (id, data) => {
  return api.patch(`/books/update/${id}`, data); 
};

export const deleteBookById = (id) => {
  return api.delete(`/books/delete/${id}`); 
};
export const generateSignedUrl = (id) => {
  return api.get(`/books/generateSignedUrl/${id}`); 
};
//author
export const getAuthors = () => {
  return api.get("/authors/getall"); 
};

export const getAuthorsById = (id) => {
  return api.get(`/authors/${id}`);
};


export const postAuthor = (data) => {
  return api.post("/authors/create", data);
};
export const updateAuthors = (id, data) => {
  return api.patch(`/authors/update/${id}`, data); 
};


export const deleteAuthorById = (id) => {
  return api.delete(`/authors/delete/${id}`); 
};
//news-articles
export const getAllNewsarticles = () => {
  return api.get("/news-articles/getall"); 
};

export const getNewsarticlesById = (slug) => {
  return api.get(`/news-articles/getNewsBySlug/${slug}`);
};

export const postNewsarticles = (data) => {
  return api.post("/news-articles/create", data);
};
export const uploadPhoto = (data) => {
  return api.post("/news-articles/addphoto", data);
};
export const updateNewsarticles = (id, data) => {
  return api.patch(`/news-articles/update/${id}`, data); 
};

export const deleteNewsarticlesBySlug = (slug) => {
  return api.delete(`/news-articles/delete/${slug}`);
};


export const getFilteredNewsData = (data) => {
  return api.get(`/news-articles/filtered-News`, {
    params: {
      categoryName: data?.categoryName,
      type: data?.type,
      count: data?.count
    }
  });
};


//payment

export const createPayment =(data)=>{
  return api.post(`/payments/create`,data);
}

export const getAllPayments = () => {
  return api.get('/payments/getall')
    .then((response) => {
      console.log('API Response:', response.data);
      return response;
    })
    .catch((error) => {
      console.error('API Error:', error.response?.status, error.response?.data);
      throw error;
    });
};

