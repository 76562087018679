import React, { useState, useEffect } from 'react';
import { Box, Button, Card, TextField, Typography, Grid, Container, InputLabel, FormControl } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import styled from '@emotion/styled';
import { postAuthor, updateAuthors, getAuthorById } from '../../Services/AdminServices'; // Assuming the API service for updating authors
import ResponsiveDrawer from '../../Admin/Components/Drawer/Drawer'; // Import ResponsiveDrawer
import toast, { Toaster } from 'react-hot-toast';

// Styled components for consistent look and feel
const card = styled(Card)(({ theme }) => ({
  backgroundColor: "#fff",
  boxShadow: 'none',
  marginBottom: 20,
  width: '70%',
  '@media (max-width: 1200px)': {
    width: '70%', // Adjust width for desktop
  },
  '@media (max-width: 992px)': {
    width: '100%', // Adjust width for tablets
  },
  '@media (max-width: 768px)': {
    width: '100%', // Adjust width for Mobiles
  },
}));

const NewAuthor = () => {
  const navigate = useNavigate();
  const { authorId } = useParams(); // Get the authorId from the URL (for edit mode)
  const [name, setName] = useState('');
  const [bio, setBio] = useState('');
  const [photo, setPhoto] = useState(null); // File for the photo upload
  const [loading, setLoading] = useState(false);
  const [nameError, setNameError] = useState(false);
  const [photoError, setPhotoError] = useState(false);
  const [isLoading, setIsLoading] = useState(true); // To handle loading state for edit mode
  const [existingPhoto, setExistingPhoto] = useState(null); // To store the existing photo URL

  // Fetch author data when in edit mode
  useEffect(() => {
    if (authorId) {
      const fetchAuthor = async () => {
        try {
          setIsLoading(true);
          const response = await getAuthorById(authorId);
          const author = response?.data;
          if (author) {
            setName(author.name || '');
            setBio(author.bio || '');
            setExistingPhoto(author.photo || null); // Set the existing photo URL if available
            setPhoto(null); // Reset the photo in case of editing
          }
        } catch (err) {
          toast.error(err?.response?.data?.message || 'Error fetching author details');
        } finally {
          setIsLoading(false);
        }
      };
      fetchAuthor();
    } else {
      setIsLoading(false); // In create mode, no need to fetch data
    }
  }, [authorId]);

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
  
    // Validation
    let error = false;
    if (!name) {
      setNameError(true);
      error = true;
    }
    if (!photo && !existingPhoto && !authorId) { // Ensure photo is not missing
      setPhotoError(true);
      error = true;
    }
  
    if (error) return;
  
    setLoading(true);
  
    // Declare formData
    const formData = new FormData();
    formData.append('name', name);
    formData.append('bio', bio);
  
    // For updating, use the new photo if uploaded, else use the existing photo URL
    if (photo) {
      formData.append('photo', photo); // Add the new photo if uploaded
    } else if (existingPhoto) {
      formData.append('photo', existingPhoto); // Send the existing photo URL if no new photo is uploaded
    }
  
    try {
      let response;
      if (authorId) {
        // If authorId exists, it's an update request
        response = await updateAuthors(authorId, formData);
        toast.success(response?.data?.message || 'Author updated successfully');
      } else {
        // Otherwise, create a new author
        response = await postAuthor(formData);
        toast.success(response?.data?.message || 'Author created successfully');
      }
  
      setTimeout(() => {
        navigate('/admin/authors'); // Navigate to the authors list after success
      }, 2000);
    } catch (err) {
      toast.error(err?.response?.data?.message || (authorId ? 'Error updating author' : 'Error creating author'));
    } finally {
      setLoading(false);
    }
  };
  

  // Handle photo preview
  const handlePhotoChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setPhoto(file); // Update the photo state with the new file
    }
  };

  return (
    <Container maxWidth="lg" sx={{ padding: 4 }}>
      <Grid container spacing={2}>
        {/* Sidebar (Responsive Drawer) */}
        <Grid item xs={12} lg={2}>
          <ResponsiveDrawer />
        </Grid>

        {/* Main Content */}
        <Grid item xs={12} lg={10} sx={{ mt: {xs:4,sm:10} }}>
          <Box sx={{ mb: 1}}>
            <Grid container alignItems="center">
              <Grid item xs>
                <Typography variant="h5" gutterBottom sx={{ fontWeight: 'bold'}}>
                  {authorId ? 'Edit Author' : 'Add New Author'}
                </Typography>
              </Grid>
            </Grid>
          </Box>

          {/* Author Form */}
          <Box component={card}>
            {!isLoading && (
              <Box className="new-author-padding">
                {/* Input for Author Name */}
                <TextField
                  error={nameError}
                  id="author-name"
                  label="Author Name"
                  placeholder="Enter Author Name"
                  variant="outlined"
                  fullWidth={true}
                  required={true}
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  sx={{ mb: 5 }}
                  helperText={nameError && "Please enter the name"}
                />

                {/* Input for Author Bio */}
                <TextField
                  id="author-bio"
                  label="Bio"
                  placeholder="Enter Author Bio"
                  variant="outlined"
                  fullWidth={true}
                  value={bio}
                  onChange={(e) => setBio(e.target.value)}
                  sx={{ mb: 5 }}
                  multiline
                  rows={4}
                />

                {/* Input for Author Photo */}
                <FormControl fullWidth sx={{ mb: 5 }} required={true} error={photoError}>
                  <InputLabel id="photo-upload-label"></InputLabel>
                  <input
                    type="file"
                    id="photo-upload"
                    accept="image/*"
                    onChange={handlePhotoChange}
                    style={{ display: 'none' }}
                  />
                  <label htmlFor="photo-upload">
                    <Button
                      variant="outlined"
                      component="span"
                      fullWidth
                      sx={{ textAlign: 'center' }}
                    >
                      {photo ? 'Change Photo' : 'Upload Photo'}
                    </Button>
                  </label>
                  {photo && (
                    <Box sx={{ mt: 2, display: 'flex', justifyContent: 'center' }}>
                      <img
                        src={URL.createObjectURL(photo)}
                        alt="Selected"
                        style={{ maxWidth: '200px', maxHeight: '200px', objectFit: 'cover' }}
                      />
                    </Box>
                  )}

                  {/* Display existing photo if in edit mode */}
                  {existingPhoto && !photo && (
                    <Box sx={{ mt: 2, display: 'flex', justifyContent: 'center' }}>
                      <img
                        src={existingPhoto}
                        alt="Existing"
                        style={{ maxWidth: '200px', maxHeight: '200px', objectFit: 'cover' }}
                      />
                    </Box>
                  )}
                </FormControl>
              </Box>
            )}

            {/* Submit Button */}
            <Box className="new-author-padding">
              <Button
                variant="contained"
                style={{ backgroundColor: "#A56266", color: "white", borderRadius: 10, fontSize: 15 }}
                onClick={handleSubmit}
                disabled={loading || isLoading} // Disable the button while loading
              >
                {loading ? 'Saving...' : authorId ? 'Update Author' : 'Save Author'}
              </Button>
            </Box>
          </Box>

          {/* Toast Notifications */}
          <Toaster
            position="top-center"
            reverseOrder={false}
            toastOptions={{
              success: {
                style: {
                  background: 'rgb(46, 125, 50)',
                  color: 'white',
                },
                iconTheme: {
                  primary: 'rgb(46, 125, 50)',
                  secondary: 'white',
                },
              },
              error: {
                style: {
                  background: 'rgb(211, 47, 47)',
                  color: 'white',
                },
                iconTheme: {
                  primary: 'rgb(211, 47, 47)',
                  secondary: 'white',
                },
              },
            }}
          />
        </Grid>
      </Grid>
    </Container>
  );
};

export default NewAuthor;
