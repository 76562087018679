import React, { useState, useEffect } from 'react';
import './MagazineStyles.css';
import { styled } from '@mui/system';
import aboutdetail from '../../../Assets/wallpaper.jpg';
import { Box, Typography } from '@mui/material';
import slide from '../../../Assets/pubs.jpeg';
import { useNavigate } from 'react-router-dom';
import { getAllMagazines } from '../../../Services/AdminServices'; // Import the API service
import toast, { Toaster } from 'react-hot-toast';

const MonthlyMagazine = () => {
  const navigate = useNavigate();
  const [hoveredCard, setHoveredCard] = useState(null);
  const [magazines, setMagazines] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchMagazines = async () => {
      try {
        const response = await getAllMagazines();
        console.log('Raw API Response:', response);
        console.log('Magazines Array:', response.data.magazines); // Log the magazines data structure
  
        if (response && response.data && Array.isArray(response.data.magazines)) {
          // Check if coverPhoto exists for each magazine
          const magazinesWithValidCover = response.data.magazines.map((magazine) => ({
            ...magazine,
            coverPhoto: magazine.coverPhoto || 'default-cover.jpg' // Fallback if coverPhoto is undefined
          }));
          setMagazines(magazinesWithValidCover);
        } else {
          throw new Error('Invalid data structure');
        }
      } catch (error) {
        console.error('Error fetching magazines:', error);
        setError('Failed to load magazines');
        toast.error('Failed to load magazines');
      } finally {
        setLoading(false);
      }
    };
  
    fetchMagazines();
  }, []);
  

  // Function to handle download using the base64 encoded PDF string
 // Function to handle PDF download using the URL
 const handleDownload = async (pdfUrl, magazineName) => {
  if (pdfUrl) {
    try {
      const response = await fetch(pdfUrl, {
        method: 'GET',
      });

      if (!response.ok) {
        throw new Error('Failed to fetch the PDF file.');
      }

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = `${magazineName}.pdf`; // Set the file name dynamically using magazine name
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error downloading the PDF:', error);
      toast.error('Failed to download the PDF.');
    }
  } else {
    toast.error('PDF not available.');
  }
};




  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <Typography variant="h6">Loading Magazines...</Typography>
      </Box>
    );
  }

  if (error) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <Typography variant="h6">{error}</Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ mt: { xs: 0, lg: 3 } }}>
      <HeroSection>
        <HeroContent>
          <Typography variant="h4" sx={{ fontWeight: 600, color: '#AD62AA', fontSize: { xs: '1.5rem', md: '2.5rem' }, mt: 2 }}>
            Spandanam
          </Typography>
          <Typography variant="body1" sx={{ fontSize: '1rem', color: '#AD62AA' }}>
            A Monthly Journey of Knowledge and Spiritual Awakening
          </Typography>
        </HeroContent>
      </HeroSection>

      <div className="magazine-grid">
  {Array.isArray(magazines) &&
    magazines.map((magazine) => (
      <div key={magazine._id} className={`magazine-card ${hoveredCard === magazine._id ? 'hovered' : ''}`}>
        <div className="card-inner">
          <div className="card-front">
            <div className="card-header">
              <h2>{magazine.name}</h2>
            </div>
            <div className="card-image">
              <img
                // Corrected here: magazine.coverPhoto?.url instead of coverPhoto.url
                src={magazine.coverPhoto?.url ? magazine.coverPhoto.url : "/default-cover.jpg"} // Use fallback if coverPhoto is undefined
                alt={`${magazine.name} Magazine Cover`}
              />
            </div>
          </div>
          <div className="card-back">
  <button
    className="download-btn"
    onClick={() => handleDownload(magazine.pdfFile?.url, magazine.name)} // Ensure magazine.name is passed
  >
    Download PDF
  </button>
</div>

        </div>
      </div>
    ))}
</div>


      <Box sx={{ mb: -2.5, ml: -3, mt: 2 }}>
        <img src={slide} alt="Magazine Slide" style={{ width: '100%', height: '100%' }} />
      </Box>
      <Toaster position="top-center" reverseOrder={false} />
    </Box>
  );
};

const HeroSection = styled(Box)(({ theme }) => ({
  position: 'relative',
  backgroundImage: `url(${aboutdetail})`,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  height: '100px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: 'white',
  textAlign: 'center',
  padding: theme.spacing(8, 0),
}));

const HeroContent = styled(Box)(({ theme }) => ({
  zIndex: 1,
  maxWidth: '800px',
  padding: theme.spacing(4),
}));

export default MonthlyMagazine;
