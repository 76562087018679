import React from 'react';
import { Grid, Box, TextField, Button, Checkbox, FormControlLabel, Typography, Container, Paper } from '@mui/material';
import { styled } from '@mui/material/styles';
import review from '../../../Assets/contact.jpg';
import border from '../../../Assets/border.png';

const HeroSection = styled(Box)(({ theme }) => ({
  position: 'relative',
  height: '100vh',
  
  backgroundImage: `url(${review})`,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  color: '#fff',
  padding: theme.spacing(0), // Removed any padding
  [theme.breakpoints.down('sm')]: {
    height: '60vh',
  },
  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(173, 216, 230, 0.5)', // Light blue overlay
  },
}));

const TransparentCard = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  backgroundColor: 'transparent',
  boxShadow: 'none',
  zIndex: 2,
  position: 'relative',
  color: 'white',
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(2),
  },
  '&::before': {
    content: '""',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundImage: `url(${border})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    zIndex: -1,
  },
}));

const WhiteBackgroundForm = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  backgroundColor: '#f0f8ff', // Light blue background
  boxShadow: theme.shadows[3],
  zIndex: 2,
  position: 'relative',
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(2),
  },
}));

const SubmitButton = styled(Button)(({ theme }) => ({
  backgroundColor: 'dark blue',
  color: '#fff',
  '&:hover': {
    backgroundColor: '#2e7d32',
  },
}));

const ContactUs = () => {
  return (
    <Box sx={{ mt: { xs: 0, lg: 3 } }}>
      <HeroSection>
        <Container maxWidth="lg">
          <Grid container spacing={4} alignItems="flexstart" justifyContent="flexstart">
            <Grid item xs={12} md={6} sx={{ zIndex: 2, textAlign: { xs: 'center', md: 'left' } }}>
              <Typography variant="h2" component="h1" sx={{ mb: 2,ml:4, mt: { md: 10, xs: 10, sm: 25 }, fontSize: { xs: '2.5rem', md: '3rem' } }}>
                Contact Sharon Writers 
              </Typography>
              <Typography variant="h5"  sx={{ ml: 4, fontSize: { xs: '1.2rem', md: '1.5rem' } }}>
                You are in the right place
              </Typography>
              <Container maxWidth="md" sx={{ mt: 5 }}>
                <TransparentCard>
                  <Typography variant="h6" gutterBottom>
                    Our Contact Information
                  </Typography>
                  <Typography variant="body1" paragraph>
                    <strong>Address:</strong> Sharon Headquarters,
                    Thiruvalla, Kerala, India
                  </Typography>
                  <Typography variant="body1" paragraph>
                    <strong>Phone:</strong> +91 469 260 7979
                  </Typography>
                  <Typography variant="body1" paragraph>
                    <strong>Email:</strong> info@sharonwritersforum.com
                  </Typography>
                </TransparentCard>
              </Container>
            </Grid>
            {/* <Grid item xs={12} md={6}>
              <WhiteBackgroundForm>
                <Typography variant="h4" gutterBottom>
               Get in Touch
                </Typography>
                <form noValidate autoComplete="off">
                  <TextField
                    fullWidth
                    label="First Name"
                    variant="outlined"
                    margin="normal"
                    required
                  />
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        label="Email Address"
                        variant="outlined"
                        margin="normal"
                        required
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        label="Phone Number"
                        variant="outlined"
                        margin="normal"
                        required
                      />
                    </Grid>
                  </Grid>
                  <TextField
                    fullWidth
                    label="Your Message"
                    variant="outlined"
                    margin="normal"
                    multiline
                    rows={4}
                    required
                  />
                  <FormControlLabel
                    control={<Checkbox required />}
                    label="I have read and accept the privacy policy."
                  />
                  <SubmitButton variant="contained" sx={{ mt: 2 }}>
                    Submit
                  </SubmitButton>
                </form>
              </WhiteBackgroundForm>
            </Grid> */}
          </Grid>
        </Container>
      </HeroSection>
    </Box >
  );
};

export default ContactUs;
