import { Button, Icon, InputAdornment, TextField, Typography, Grid, useMediaQuery } from '@mui/material';
import React, { useState } from 'react';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import { useNavigate } from 'react-router-dom';
import { Toaster, toast } from 'react-hot-toast';
import logoo from '../Assets/logoo.png';
import { forgot } from '../Services/AdminServices';
import MarkEmailUnreadIcon from '@mui/icons-material/MarkEmailUnread';
function Forgotpassword() {
  const navigate = useNavigate();
  const isDesktop = useMediaQuery('(min-width:600px)');

  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [otpSent, setOtpSent] = useState(false);
  const [otpValue, setOtpValue] = useState("");

  const handleForgot = async () => {
    let error = false;
    if (email === "") {
      setEmailError(true);
      error = true;
    } else {
      setEmailError(false);
    }
    if (error) return;

    try {
      const response = await forgot({ email });
      if (response.data.success) {
        toast.success(response.data.message);
        setOtpSent(true);
      } else {
        toast.error(response.data.message);
      }
    } catch (err) {
      toast.error("Something went wrong. Please try again.");
      console.error(err);
    }
  }

  const forgotButton = {
    backgroundColor: '#4a148c',
    fontSize: 15,
    marginBottom: 3,
    color: 'white',
    borderRadius: 16
  };

  const textFieldStyle = {
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#4a148c',
        borderRadius: 16
      },
      '&:hover fieldset': {
        borderColor: '#4a148c'
      },
      '&.Mui-focused fieldset': {
        borderColor: '#4a148c'
      }
    }
  };

  return (
    <Grid container style={{ height: '100vh' }}>
      {isDesktop && (
        <Grid item xs={12} sm={7}>
          <img src={logoo} alt="Logo" style={{ width: '100%', height: '100%' }} />
        </Grid>
      )}
        
      <Grid item xs={12} sm={isDesktop ? 5 : 12} style={{ backgroundColor: '#F3F4F6', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <div style={{ display: 'flex', flexDirection: 'column', width: '100%', maxWidth: '400px', padding: '20px', background: 'white', borderRadius: '16px' }}>
          <h2 style={{ color: '#4a148c', textAlign: 'center', marginBottom: '20px' }}>
            {otpSent ? 'Enter OTP' : 'Forgot Password'}
          </h2>
          
          {!otpSent ? (
            <>
              <TextField
                error={emailError}
                onChange={(e) => setEmail(e.target.value)}
                placeholder='Email'
                margin="dense"
                variant="outlined"
                fullWidth={true}
                sx={{ mb: 3, ...textFieldStyle }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Icon>
                        <EmailOutlinedIcon sx={{ color: '#4a148c' }} />
                      </Icon>
                    </InputAdornment>
                  )
                }}
              />
              <Button
                type='submit'
                style={forgotButton}
                fullWidth={true}
                color='primary'
                variant='contained'
                onClick={handleForgot}
              >
                Send OTP
              </Button>
            </>
          ) : (
            <>
            <TextField
  placeholder='Enter 5-digit OTP'
  margin="dense"
  variant="outlined"
  value={otpValue}
  onChange={(e) => setOtpValue(e.target.value)}
  fullWidth={true}
  sx={{ mb: 3, ...textFieldStyle }}
  InputProps={{
    startAdornment: (
      <InputAdornment position="start">
        <Icon>
          <MarkEmailUnreadIcon sx={{ color: '#4a148c' }} />
        </Icon>
      </InputAdornment>
    )
  }}
/>
              <Button
                type='submit'
                style={forgotButton}
                fullWidth={true}
                color='primary'
                variant='contained'
                onClick={() => navigate('/reset', { state: { email } })}
              >
                Verify OTP
              </Button>
            </>
          )}
        </div>
        <Toaster
          position="top-center"
          reverseOrder={false}
          toastOptions={{
            success: {
              style: {
                background: '#4a148c',
                color: 'white'
              },
              iconTheme: {
                primary: '#4a148c',
                secondary: 'white',
              },
            },
            error: {
              style: {
                background: 'rgb(211, 47, 47)',
                color: 'white'
              },
              iconTheme: {
                primary: 'rgb(211, 47, 47)',
                secondary: 'white',
              },
            },
          }}
        />
      </Grid>
    </Grid>
  );
}

export default Forgotpassword;